import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY ;

export const encrypt = (plainText) => {
  const cipherText = CryptoJS.AES.encrypt(plainText, secretKey).toString();
//   const encodedCipherText = encodeURIComponent((cipherText)); // Base64 encode ve özel karakterleri encode et

  return cipherText;
}

export const decrypt = (encodedCipherText) => {
//   const decodedCipherText = (decodeURIComponent(encodedCipherText)); // Özel karakterleri decode et ve Base64 decode
  const bytes = CryptoJS.AES.decrypt(encodedCipherText, secretKey);
  const plainText = bytes.toString(CryptoJS.enc.Utf8);

  return plainText;
}
