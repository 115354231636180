import { configureStore } from '@reduxjs/toolkit'
import PersonSlice from './userSlice';
import {  useDispatch, useSelector } from 'react-redux';
import LoginSlice from './LoginSlice';
import  Student_Group  from './SideBarSlice';
import LessonSubSlice from './LessonSubSlice';
import VideoSlice from './VideoSlice';
import CheetSlice from './CheetSlice';

export const store = configureStore({
  reducer: {
    user: PersonSlice,
    login: LoginSlice,
    student_group: Student_Group,
    lessonIndex: LessonSubSlice,
    videos:VideoSlice,
    cheet:CheetSlice
    

  },
})

// export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch= useDispatch;
export const useAppSelector = useSelector;