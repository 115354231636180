import React from 'react'
import { useCookies } from 'react-cookie';
import { decrypt, encrypt } from '../util/cry';

function GetMyTokenFromCookie() {
        const cookies = document.cookie.split('; ');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].split('=');
          if (cookie[0] === 'mokM') {

            return decrypt(`${cookie[1]}`);
          }
        }
        // return null;
      }
export default GetMyTokenFromCookie
