import { Button, Grid, ScrollArea } from "@mantine/core";
import React, {  useState } from "react";
import {  useAppSelector } from "../redux/store";

import LessonVideoTemp from "./LessonVideoTemp";
import { IconBook2, IconPlayerPlayFilled } from "@tabler/icons-react";
import LessonCheetTemp from "./LessonCheetTemp";
import { IconBook } from "@tabler/icons-react";

const LessonCheatRead = () => {
  const allCheets = useAppSelector((state) => state.cheet);

  const [selectedCheet, setSelectedCheet] = useState("");





  const handleOpenCheet = (video) => {
    setSelectedCheet(video);
  };


  return (
    <Grid columns={24} style={{ minHeight: "85vh" }}>
      <Grid.Col span={6}>
        <ScrollArea style={{ height: "90vh", marginTop:"2.5rem"  }} scrollbarSize={8}>
          {allCheets?.data?.map((cheet, key) => (
            <>
              <div style={{ position: "relative"}}>
                <Button
                  onClick={() => handleOpenCheet(cheet)}
                  style={{
                    borderRadius: "30px",
                    border: "3px solid black",
                    backgroundColor:
                    cheet.title == selectedCheet.title ? "#64aec8" : "#fff",
                    width: "100%",
                    height: "4.5rem",
                    margin: "0.3rem 0 0.3rem 0",
                    color: "black",
                  }}
                >
                  {cheet.title}
                </Button>
                <IconBook
                  style={{
                    position: "absolute",
                    left: 10,
                    top: "25px",
                    color: "#FBCC3B",
                  }}
                  size={35}
                />
              </div>

            </>
          ))}
        </ScrollArea>
      </Grid.Col>

      <Grid.Col span={18}>
        {selectedCheet == "" ? (
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "80%",
              borderRadius: "50px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Lütfen Seçim Yapınız</h3>
          </div>
        ) : (
          <>
            <LessonCheetTemp cheet={selectedCheet} />
            <h1
              style={{
                textDecoration: "underline",
                textDecorationColor: "#64aec8",
                textDecorationThickness: "8px",
              }}
            >
              {selectedCheet.title}
            </h1>
            <p>{selectedCheet.desc}</p>
          </>
        )}
      </Grid.Col>
      {/* <Grid.Col span={6}>3</Grid.Col> */}
    </Grid>
  );
};

export default LessonCheatRead;
