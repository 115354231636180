import { useToggle } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Container,
  Stack,
  createStyles,
  rem,
  Image,
  Center,
  Anchor,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import MetamyLogo from "../metamylogosu.png";
import axiosInstance from "../axios/axios";
import { getUserData } from "../redux/userSlice";
import { useAppDispatch } from "../redux/store";
// import LoginBackgroundImage from "../asserts/night-background.webp";
import { encrypt } from "../util/cry";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundImage: `url('https://metamy.b-cdn.net/media/images/night-background-_1_.webp')`,
  },

  form: {
    border: `${rem(2)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    //   backgroundColor:theme.colors.blue[0],
    backgroundImage: theme.fn.gradient({
      from: theme.colors.blue[1],
      to: theme.colors.gray[0],
      deg: 360,
    }),

    opacity: 0.9,
    minHeight: rem(500),
    maxWidth: rem(450),
    paddingTop: rem(100),
    borderRadius: "10px",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export default function Login(props) {
  const { classes } = useStyles();
  const Navigate = useNavigate();
  const [type, toggle] = useToggle(["login", "register"]);
  const [error, setError] = useState(false);
  const [class_name, setClassName] = useState("");
  const [token, setToken, removeToken] = useCookies(["mokM"]);
  const dispatch = useAppDispatch();

  const form = useForm({
    initialValues: {
      email: "",
      username: "",
      name: "",
      password: "",
      groupName: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 3
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const approveLogin = async (body) => {
    return await axiosInstance
      .post("api/api-token-auth/", body)
      .then((resp) => {
        if (resp.status == 200 || resp.status == 201) {
          setToken("mokM", encrypt(resp.data.token), { encode: String });
          setToken("uid", encrypt(resp.data.user_id), { encode: String });
          dispatch(getUserData(encrypt(resp.data.user_id)));
          notifications.show({
            message: "Başarılı bir şekilde giriş yapıldı",
            color: "green",
          });
        } else {
          alert("Yanlış giriş");
        }
      }).catch(e =>       notifications.show({
        message: "Hatalı giriş lütfen tekrar deneyiniz",
        color: "red",
      }))
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await approveLogin({
      username: form.values.email,
      password: form.values.password,
    });
  };


  useEffect(() => {
    if (token?.mokM) {
      setError(false);
      form.setFieldValue("password", "");
      form.setFieldValue("username", "");
      notifications.show({
        message: "Başarılı bir şekilde giriş yapıldı",
        color: "green",
      });
      // window.location.reload()
      Navigate("/");
    }
    if (error) {
      removeToken("mokM");
      notifications.show({
        message: "Hatalı giriş lütfen tekrar deneyiniz",
        color: "red",
      });
    }
  }, [token]);

  return (
    <Paper
      className={classes.wrapper}
      // p="xl"
      withBorder
      {...props}
    >
      <Container
        p="xl"
        className={classes.form}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(10px)",
          borderRadius: "16px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          // border: "1px solid rgba(255, 255, 255, 0.125)",
        }}
      >
        <Center p="xl">
          <Image src={MetamyLogo} width={300} />
        </Center>
        <Divider
          label="Lütfen e-posta adres ve şifreniz ile giriş yapınız"
          labelPosition="center"
          my="lg"
          labelProps={{style: {fontSize:"13px"} }}
        />

        <form onSubmit={handleLogin}>
          <Stack>
            <TextInput
              required
              label="Email"
                    
              placeholder="example@xyz.com"
              value={form.values.email}
              onChange={(event) =>
                form.setFieldValue("email", event.currentTarget.value)
              }
              error={form.errors.email && "Invalid email"}
              radius="md"
              autoFocus
            />

            <PasswordInput
              required
              label="Password"
              placeholder="Your password"
              value={form.values.password}
              onChange={(event) =>
                form.setFieldValue("password", event.currentTarget.value)
              }
              error={error}
              radius="md"
            />
          </Stack>
          <Center>
            <Group position="apart" mt="xl">
              <Button type="submit" radius="xl" size="lg">
                Login
              </Button>
              <Anchor
                href="#"
                onClick={(event) => event.preventDefault()}
                pt={2}
                fw={500}
                fz="sm"
              >
                Parolanızı mı unuttunuz?
              </Anchor>
            </Group>
          </Center>
        </form>
      </Container>
    </Paper>
  );
}
