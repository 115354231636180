// api.js

import axios from 'axios';
import { useCookies } from 'react-cookie';
import GetMyTokenFromCookie from './getToke';



const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_URL_GLOBAL}/`, // .env dosyasındaki API URL'sini kullanıyoruz
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Token ${GetMyTokenFromCookie()}`,
  },
});

export default axiosInstance;