import { Drawer, Badge } from "@mantine/core";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../axios/axios";

function LessonNotesTemp({ selectedSubject }) {
  const [subjectDetail, setSubjectDetails] = useState();
  // const [screenState, setScreenState] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subjects_data =
          selectedSubject !== undefined &&
          (await axiosInstance.get(
            `api/lesson_sub_detail/?lessonsubject=${selectedSubject}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ));
        setSubjectDetails(subjects_data.data);
      } catch (error) {
      }
    };

    fetchData();
  }, [selectedSubject]);

  // const handleFullScreen = () => {
  //   setScreenState(!screenState);
  // };

  return (
    <div style={{padding:"2rem", backgroundColor:"ButtonFace" }} >

        {subjectDetail !== undefined && (
          <div  className="article"
            dangerouslySetInnerHTML={{ __html: subjectDetail[0]?.content }}
          />
        )}
      {/* </Drawer> */}
    </div>
  );
}
export default LessonNotesTemp;
