import { useNavigate, useParams } from "react-router-dom";
import CourseCard from "../components/CourseCard";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  Accordion,
  Anchor,
  Breadcrumbs,
  Grid,
  LoadingOverlay,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { IconBook, IconReload } from "@tabler/icons-react";
import LessonNotesTemp from "../components/LessonNotesTemp";

const Courses = ({ setSelectedSubject }) => {
  const Navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const student_group = useAppSelector((state) => state.student_group);

  const [selectedSub, setSelectedSub] = useState();
  const documentsSub = useAppSelector((state) => state.lessonIndex);

  return (
    <div>
      {/* <Breadcrumbs style={{ margin: "1rem" }}>{breadcrapItems}</Breadcrumbs> */}
      <LoadingOverlay
        visible={student_group?.loading || user.loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 10 }}
      />

      <h1 style={{ textAlign: "center" }}>Documents</h1>
      <div>
        <Grid>
          <Grid.Col span={2}>
            {documentsSub.lessons?.map((e,i) => (
              <Accordion>
                <Accordion.Item
                  key={i}
                  value={e?.lesson_name == "" ? "Bos" : e.lesson_name}
                  onClick={() => setSelectedSubject(e?.id)}
                >
                  <Accordion.Control icon={<IconBook color="#64AEC8" />}>
                    {e?.lesson_name}
                  </Accordion.Control>
                  {documentsSub.data?.map((sub) => (
                    <Accordion.Panel
                      style={{ cursor: `pointer` }}
                      onClick={() => setSelectedSub(sub?.id)}
                    >
                      {sub?.sub_title}
                    </Accordion.Panel>
                  ))}
                </Accordion.Item>
              </Accordion>
            ))}
          </Grid.Col>
          <Grid.Col span={10} >
            <LessonNotesTemp selectedSubject={selectedSub} />
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
};

export default Courses;
