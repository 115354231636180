
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axios/axios";

const initialState = {
  data: [],
  lessons:[],
  loading: false,
  error: "",
};

export const getLessonIndex = createAsyncThunk(
  "getLessonIndex",
  async (id) => {
    const response = await axiosInstance.get(
      `api/lesson_sub/?lesson=${id}`,
    );
    return response.data;
  }
);
export const getLesson = createAsyncThunk(
  "getLesson",
  async (id) => {
    const response = await axiosInstance.get(
      `api/lesson/${id}`,
    );
    return response.data;
  }
);
export const getCourseLesson = createAsyncThunk(
  "getCourseLesson",
  async (student_group) => {
    const response = await axiosInstance.get(
      `api/lesson/?student_group=${student_group}`,
    );
    return response.data;
  }
);
export const getPublicCourseLesson = createAsyncThunk(
  "getPublicCourseLesson",
  async () => {
    const response = await axiosInstance.get(
      `api/lesson/?is_public=${true}`,
    );
    return response.data;
  }
);

export const LessonSubSlice = createSlice({
  name: "lessonIndex",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLessonIndex.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLessonIndex.fulfilled, (state,action) => {
      state.loading = false;
      state.data = action.payload
    });
    builder.addCase(getCourseLesson.fulfilled, (state,action) => {
      state.loading = false;
      state.lessons = action.payload
    });
  },
});

export default LessonSubSlice.reducer;