import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Navbar from "../components/Navbar";
import Register from "../pages/Register";
import Sidebar from "../components/Sidebar";
import { useCookies } from "react-cookie";
import { getRoles, getUserData } from "../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";

import PasswordChange from "../pages/PasswordChange";

import AdminAddClass from "../components/AdminAddClass";
import AdminAddGroup from "../components/AdminAddGroup";
import { AdminUserList } from "../components/AdminUserList";
import AdminAddTerm from "../components/AdminAddTerm";


const AppRouter = () => {
  const [token, setToken, removeToken] = useCookies(["mokM"]);
  const user = useAppSelector((state) => state.user);
  const currentUser = user.data && user.data;




  const dispatch = useAppDispatch();
  useEffect(() => {
    token?.mokM && dispatch(getUserData(token.uid));
  }, [token]);
  useEffect(() => {
    dispatch(getRoles());
  }, [currentUser]);
  return (
    <div>
      <BrowserRouter>
        {token?.mokM ? (
          currentUser?.is_staff === false &&
          currentUser?.first_login === false ? (
            <Routes>
              <Route path="/" element={<PasswordChange />} />
            </Routes>
          ) : (
            <div>
              <Navbar />
              <div
                style={{ display: currentUser?.is_staff === true ? "flex" : "block",  }}
                className="screens-container"
              >
                {currentUser?.is_staff === true && <Sidebar />}
                <div
                  style={{ minWidth: "90%", overflow: "hidden" }}
                  className="screens-section-container"
                >
                  <Routes>
                    <Route path="/" element={<Home />} />
                    {currentUser?.is_staff && (
                      <>
                      <Route path="/register" element={<Register />} />
                      <Route path="/add-class" element={<AdminAddClass />} />
                      <Route path="/add-group" element={<AdminAddGroup />} />
                      <Route path="/add-student" element={<AdminUserList />} />
                      <Route path="/add-term" element={<AdminAddTerm />} />
                      </>
                    )}
                
                  </Routes>
                </div>
              </div>
            </div>
          )
        ) : (
          // <Routes>
            <Login />
          // </Routes>
        )}
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
