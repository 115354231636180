import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import type { PayloadAction } from "@reduxjs/toolkit";
import { useCookies } from "react-cookie";
import axios from "axios"
import axiosInstance from "../axios/axios";
import { useAppSelector } from "./store";


// export type User = Root2[]

// export type DataFormat = Root2[]

// export interface Root2 {
//   id: string
//   group_lessons: GroupLesson[]
//   group_name: string
// }

// export interface GroupLesson {
//   id:string
//   lesson_name: string
//   lesson_image:string
//   created:string
// }

//   interface UserState {
//     data: DataFormat | null,
//     loading:boolean,
//     error:string,
//   }
  
  const initialState  = {
    data:null,
    loading:false,
    error:"",
  }
  


export const getStudentGroup = createAsyncThunk("getStudentGroup", async () => {
    const response = await axiosInstance.get(`api/student_group/`)
    return response.data
})




export const Student_Group = createSlice({
    name: "student_group",
    initialState,
    reducers: {
    //   addPerson: (state, action: PayloadAction<{ name: string }>) => {
    //     state.persons.push({
    //       id: state.persons.length,
    //       name: action.payload.name,
    //     });
    //   },
    },
    extraReducers: (builder) => {
      builder.addCase(getStudentGroup.pending, (state, action) => {
        state.loading = true
      });
      builder.addCase(getStudentGroup.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
      });

  
    //   builder.addCase(savePerson.fulfilled, (state, action) => {
    //     state.persons.push(action.payload);
    //   });
    },
  });

  export default Student_Group.reducer;
