import React, { useEffect, useState } from "react";
import "./HomeTabs.css";
import VideoList from "./LessonVideo";
import LessonCheet from "./LessonCheet";
import InterviewQue from "./InterviewQue";
import LessonIndexTemp from "./LessonIndexTemp";
import {  Avatar,  Menu, Select } from "@mantine/core";
import {  useAppSelector } from "../redux/store";
import axiosInstance from "../axios/axios";
import { IconCertificate2 } from "@tabler/icons-react";
import { useNavigate } from "react-router";

const HomeTabs = () => {
  const user = useAppSelector((state) => state.user);
  const currentUser = user?.data && user.data;
  const [selectedTerm, setSelectesTerm] = useState(localStorage.getItem("selectedTerm"));
  const [selectedGroup, setSelectedGroup] = useState(localStorage.getItem("selectedGroup"));
  const [term, setTerm] = useState([]);

  useEffect(() => {
    async function getTerm() {
      const response = await axiosInstance.get(`api/term/`);
      setTerm(response.data);
    }
    getTerm();
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("selectedTerm" , selectedTerm)

  }, [selectedTerm]);
  useEffect(() => {
    localStorage.setItem("selectedGroup" , selectedGroup)

  }, [selectedGroup]);
  return (
    <div>

      <section>
        <input
          type="radio"
          id="profile"
          defaultValue={1}
          name="tractor"
          checked={window.location.pathname == "/records"}
          onClick={() => navigate("records")}
          // defaultChecked="checked"
        />
        <input
          type="radio"
          id="settings"
          defaultValue={2}
          name="tractor"
          checked={window.location.pathname == "/document"}
          onClick={() => navigate("document")}
        />
        <input
          type="radio"
          id="posts"
          defaultValue={3}
          name="tractor"
          checked={window.location.pathname == "/sheet"}
          onClick={() => navigate("sheet")}
        />
        <input
          type="radio"
          id="books"
          defaultValue={4}
          name="tractor"
          checked={window.location.pathname == "/interview"}
          onClick={() => navigate("interview")}
        />
        <nav>
          <label htmlFor="profile">Records</label>
          <label htmlFor="settings">Documents</label>
          <label htmlFor="posts">Cheat Sheet</label>
          <label htmlFor="books">Question Bank</label>
          {/* <label htmlFor="cms">Cms</label> */}
        </nav>

        <article className="uno">
          {/* {window.location.pathname == "/records" && ( */}
            <VideoList
              selectedTerm={selectedTerm}
              selectedGroup={selectedGroup}
            />
          {/* )} */}
        </article>
        <article className="dos">
          {/* {window.location.pathname == "/document" && ( */}
            <LessonIndexTemp selectedGroup={selectedGroup} />
          {/* )} */}
        </article>
        <article className="tres">
          {/* {window.location.pathname == "/sheet" && ( */}
             {/* <Lessons selectedGroup={selectedGroup} /> */}
            <LessonCheet selectedGroup={selectedGroup} />
          {/* )} */}
        </article>
        <article className="cuatro">
          {/* {window.location.pathname == "/interview" &&  */}
          <InterviewQue  selectedGroup={selectedGroup}  />
          {/* } */}
        </article>
        {/* <article className="cmss">
          {window.location.pathname == "/cms" &&
           <Cms />}
        </article> */}
      </section>
      <Menu
        width={200}
        shadow="xl"
        closeOnItemClick={false}
        style={{
          cursor: `pointer`,
          height: "40px",
          minWidth: "150px",
          marginTop: "1.2rem",
        }}
      >
        <Menu.Target>
          <Avatar variant="filled" radius="xl" size="lg" color="dark">
            <IconCertificate2 color="white" size={25} /> Kurslar
          </Avatar>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item>
            {(user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici") && (
              <Select
                label="Dönem Seçiniz"
                placeholder="Dönem Seç"
                data={term?.map((term) => {
                  return { value: term.id, label: term.term_name };
                })}
                value={selectedTerm}
                onChange={setSelectesTerm}
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#64aec8",
                  margin: "0.3rem",
                  textAlign: `center`,
                }}
              />
            )}
          </Menu.Item>
          <Menu.Item>
            <Select
              label="Kurs Seçiniz"
              placeholder="Kurs Seç"
              data={currentUser?.student_group?.map((group) => {
                return { value: group.id, label: group.group_name };
              })}
              // defaultValue={"Seçiniz"}
              value={selectedGroup}
              onChange={setSelectedGroup}
              style={{
                borderRadius: "30px",
                backgroundColor: "#64aec8",
                margin: "0.3rem",
                textAlign: `center`,
              }}
            />
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default HomeTabs;
