import { Button, Grid, ScrollArea } from "@mantine/core";
import React, {  useState } from "react";
import {  useAppSelector } from "../redux/store";

import LessonVideoTemp from "./LessonVideoTemp";
import { IconArrowAutofitLeft, IconPlayerPlayFilled, IconSquareChevronsRight } from "@tabler/icons-react";
import FileUpload from "./FileDropZone";
import { IconSquareChevronsLeft } from "@tabler/icons-react";

const Records = () => {
  const allVideos = useAppSelector((state) => state.videos);
  const [toggleMenu, setToggleMenu] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false);


  const [selectedVideo, setSelectedVideo] = useState("");





  const handleOpenVideoPopup = (video) => {
    setSelectedVideo(video);
  };


  return (
    <Grid columns={24} style={{ minHeight: "85vh" }}>
    {!toggleMenu &&
      <Grid.Col span={6}>
        <ScrollArea style={{ height: "90vh", marginTop:"2.5rem"  }} scrollbarSize={8}>
          {allVideos?.data?.map((video, key) => (
            <>
              <div style={{ position: "relative",maxWidth:`100%`}}>
                <Button
                  onClick={() => handleOpenVideoPopup(video)}
                  style={{
                    borderRadius: "30px",
                    border: "3px solid black",
                    backgroundColor:
                    video.id == selectedVideo.id ? "#64aec8" : "#fff",
                    width:`100%`,
                    height: "4.5rem",
                    margin: "0.3rem 0 0.3rem 0",
                    color: "black",
                    

                  }}
                >
                  {video.title.slice(0,30)}
                </Button>
                <IconPlayerPlayFilled
                  style={{
                    position: "absolute",
                    left: 0,
                    top: "25px",
                    color: "#FBCC3B",
                  }}
                  size={35}
                />
              </div>

            </>
          ))}
        </ScrollArea>
      </Grid.Col>}

      <Grid.Col span={toggleMenu ? 24 : 18}>
        {selectedVideo == "" ? (
          <div
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "80%",
              borderRadius: "50px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Lütfen Seçim Yapınız</h3>
          </div>
        ) : (
          <>
          <div style={{position:`relative`}}>
          {!isPlaying ? 
          ((toggleMenu) ? 
          <IconSquareChevronsRight onClick={() =>setToggleMenu(!toggleMenu)}  size={40} color="#FBCC3B" style={{cursor:`pointer`,position:`absolute`, left:0, top:`50%`,zIndex:999}}></IconSquareChevronsRight>
          :
          <IconSquareChevronsLeft onClick={() =>setToggleMenu(!toggleMenu)}  size={40} color="#FBCC3B" style={{cursor:`pointer`,position:`absolute`, left:0, top:`50%`,zIndex:999}}></IconSquareChevronsLeft>
        ) : ``}
            <LessonVideoTemp menuState={toggleMenu} setIsPlaying={setIsPlaying} video={selectedVideo} />
            </div>
            <h1
              style={{
                textDecoration: "underline",
                textDecorationColor: "#64aec8",
                textDecorationThickness: "8px",
              }}
            >
              {selectedVideo.title}
            </h1>
            <h3 >{selectedVideo.desc}</h3>
            <h2 style={{textDecoration:"underline"}}>Kaynaklar</h2>
            <FileUpload selectedVideo={selectedVideo.id} />
          </>
        )}
      </Grid.Col>
      {/* <Grid.Col span={6}>3</Grid.Col> */}
    </Grid>
  );
};

export default Records;
