import {
  Navbar,
  Group,
  Code,
  createStyles,
  rem,
  Button,
} from "@mantine/core";
import { IconNotes } from "@tabler/icons-react";
import { UserButton } from "./UserButton/UserButton";
import { LinksGroup } from "./NavbarLinksGroup/NavbarLinksGroup";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { getStudentGroup } from "../redux/SideBarSlice";
import { useNavigate } from "react-router";
// import { Logo } from './Logo';

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.fn.linearGradient(45, "red", "blue"),
    paddingBottom: 0,
    zIndex: 9999999,
    minHeight: "100vh",
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
  },

  footer: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
}));

export default function Sidebar() {
  const { classes } = useStyles();
  const user = useAppSelector((state) => state.user);
  const student_group = useAppSelector((state) => state.student_group);
  const currentUser = user?.data && user.data;
  const student_group_list = student_group.data && student_group.data;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getStudentGroup());
  }, []);

  const data = student_group_list?.map((item) => {
    return (
      currentUser?.student_group?.includes(item.id) && {
        label: item.group_name,
        icon: IconNotes,
        links: item.group_lessons.map((lesson) => {
          return { label: lesson.lesson_name, link: `${lesson.lesson_name}` };
        }),
      }
    );
  });

  const newData = data?.filter((e) => e !== false);

  const links = newData?.map((item, key) => <LinksGroup {...item} key={key} />);

  return (
    <Navbar
      fixed={false}
      position={{ top: 0, left: 0 }}
      width={{ sm: 150 }}
      height={{ sm: 500 }}
      // p="xs"
      className={classes.navbar}
      style={{

        // boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.74)",
        // boxShadow: "2px 15px 20px 0px rgba(0,0,0,0.74)",
        // backgroundImage: `url('https://metamy.b-cdn.net/media/images/sidebar.webp')`,
        // border: "none",
        // backgroundSize: "cover",
      }}
    >
      <Navbar.Section
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2vh",
          border: "none",
        }}
        className={classes.header}
      >
        <Group position="apart">
          {/* <Logo width={rem(120)} /> */}
          <Code sx={{ fontWeight: 700 }}>v0.0.2</Code>
        </Group>
      </Navbar.Section>
      <Navbar.Section>
        <div className={classes.linksInner}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              flexDirection: "column",
              background: "none",
            }}
          >
            <Button
              m="1"
              radius="none"
              sx={{
                color: " black",
                background: "none",
                width: "100%",
                "&:hover": {
                  background: "#2290DC",
                  color: "#ffffff",
                },
              }}
              onClick={() => navigate("/")}
            >
              Ana Sayfa
            </Button>
            <Button
              radius="none"
              sx={{
                color: " black",
                background: "none",
                width: "100%",
                "&:hover": {
                  background: "#2290DC",
                  color: "#ffffff",
                },
              }}
              onClick={() => navigate("/add-student")}
            >
              Ogrenci Listesi
            </Button>
            <Button
              m="1"
              radius="none"
              sx={{
                color: " black",
                background: "none",
                width: "100%",
                "&:hover": {
                  background: "#2290DC",
                  color: "#ffffff",
                },
              }}
              onClick={() => navigate("/register")}
            >
              Kayıt
            </Button>
            <Button
              radius="none"
              sx={{
                color: " black",
                background: "none",
                width: "100%",
                "&:hover": {
                  background: "#2290DC",
                  color: "#ffffff",
                },
              }}
              onClick={() => navigate("/add-class")}
            >
              Sınıf Ekle
            </Button>
            <Button
              radius="none"
              sx={{
                color: " black",
                background: "none",
                width: "100%",
                "&:hover": {
                  background: "#2290DC",
                  color: "#ffffff",
                },
              }}
              onClick={() => navigate("/add-group")}
            >
              Grup Ekle
            </Button>
            <Button
              radius="none"
              sx={{
                color: " black",
                background: "none",
                width: "100%",
                "&:hover": {
                  background: "#2290DC",
                  color: "#ffffff",
                },
              }}
              onClick={() => navigate("/add-term")}
            >
              Donem Ekle
            </Button>
           
          </div>
        </div>
      </Navbar.Section>

      {/* <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section> */}

      {/* <Navbar.Section
        sx={{ width: "13.5rem", border: "none" }}
        className={classes.footer}
      >
        <UserButton
          sx={{
            background: "none",
            fontColor: "black",
            "&:hover": {
              background: "#2290DC",
              color: "#ffffff",
            },
          }}
          image={currentUser?.image}
          name={currentUser?.first_name || ""}
          email={currentUser?.username || ""}
        />
      </Navbar.Section> */}
    </Navbar>
  );
}
