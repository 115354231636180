import {
  Button,
  Center,
  Container,
  List,
  Paper,
  Select,
  Stack,
  Table,
  TextInput,
  createStyles,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
const useStyles = createStyles((theme) => ({
  wrapper: {
    height: rem(900),
    backgroundSize: "cover",
    // backgroundImage:
    //   "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
  },

  form: {
    border: `${rem(2)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    //   backgroundColor:theme.colors.blue[0],
    backgroundImage: theme.fn.gradient({
      from: theme.colors.blue[1],
      to: theme.colors.gray[0],
      deg: 360,
    }),

    opacity: 0.9,
    minHeight: rem(400),
    maxWidth: rem(450),
    padding: rem(10),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },
}))  

const AdminAddGroup = (props) => {
  const [postData, setPostData] = useState("");
  const [student_group, setStudentGroup] = useState();
  const { classes } = useStyles();
  useEffect(() => {
      fetchStudentGroup().then((resp) => setStudentGroup(resp.data));
      }, []);
  const form = useForm({
    initialValues: {
      student_group: "",
      lesson: "",
      lesson_image: "",
    },
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setPostData("");
    try {
      await axiosInstance.post("api/student_group/", {
        group_name: form.values.student_group,
        group_lessons: [],
      });
      setPostData("işlem Başarılı");
    } catch (error) {
      setPostData("Hata");
      return error;
    }
  };


  const fetchStudentGroup = async () => {
    return await axiosInstance.get("api/student_group/").then((response) => {
      return response;

    });
  };

  return (
       <Paper className={classes.wrapper} radius="md" p="xl" withBorder {...props}>
      <Container className={classes.form}>
      <form  onSubmit={handleSubmit}>
        <Center m="sm" >
          <Stack>
        <h3 style={{ textAlign: "center" }}>Yeni Grup Ekle</h3>
            <TextInput
              label="Grup Adı"
              placeholder="Grup"
              value={form.values.student_group}
              onChange={(event) =>
                form.setFieldValue("student_group", event.currentTarget.value)
              }
              radius="md"
              autoFocus
            />
            <Button type="submit">Ekle</Button>
          </Stack>
        </Center>
      </form>
     
        <Center>
        <List>
        {student_group !== undefined && student_group?.map((e) => (
            <List.Item>{e.group_name}</List.Item>
          ))}
        </List>
      </Center>

      </Container>
    </Paper>

       
  );
};

export default AdminAddGroup;
