import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"
import axiosInstance from "../axios/axios";

  
  const initialState  = {
    data:[],
    loading:false,
    error:"",
  }
  


export const loginUser = createAsyncThunk("loginUser", async (body) => {
    const response = await axiosInstance.post("api/api-token-auth/",{username:body.username, password:body.password},
    )
    return response.data
})



export const LoginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
      builder.addCase(loginUser.pending, (state, action) => {
        state.loading = true
      });
      builder.addCase(loginUser.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
      });
  
    //   builder.addCase(savePerson.fulfilled, (state, action) => {
    //     state.persons.push(action.payload);
    //   });
    },
  });

  export default LoginSlice.reducer;
