import React from 'react'
import { useCookies } from 'react-cookie';
import { decrypt } from '../util/cry';

function GetUidFromCookie() {
    // const [token, setToken, removeToken] = useCookies(["mytoken"]);

        const cookies = document.cookie.split('; ');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].split('=');
          if (cookie[0] === 'uid') {
            if(cookie[1] !== undefined)
            return decrypt(`${cookie[1]}`);
          }
        }
        return null;
      }
export default GetUidFromCookie
