import { Button, Center, List, Paper, Stack, Container, TextInput, createStyles, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
const useStyles = createStyles((theme) => ({
  wrapper: {
    height: rem(900),
    backgroundSize: "cover",
    // backgroundImage:
    //   "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
  },

  form: {
    border: `${rem(2)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    //   backgroundColor:theme.colors.blue[0],
    backgroundImage: theme.fn.gradient({
      from: theme.colors.blue[1],
      to: theme.colors.gray[0],
      deg: 360,
    }),

    opacity: 0.9,
    minHeight: rem(400),
    maxWidth: rem(450),
    padding: rem(10),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },
}))  

const AdminAddClass = (props) => {
  const [postData, setPostData] = useState("");
  const [class_names, setClassNames] = useState([]);
  

  const { classes } = useStyles();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setPostData("");
    try {
      await axiosInstance.post("api/class_name/", {
        class_names: form.values.class_name,
      });
      setPostData("işlem Başarılı");
    } catch (error) {
      setPostData("Hata");
      return error;
    }
  };

  const form = useForm({
    initialValues: {
      class_name: "",
    },
  });

  const fetchStudentClassName = async () => {
    return await axiosInstance.get("api/class_name/").then((response) => {
      return response;
    });
  };
  useEffect(() => {
    fetchStudentClassName().then((resp) => setClassNames(resp.data));
  }, [postData]);

  return (
        <Paper className={classes.wrapper} radius="md" p="xl" withBorder {...props}>
      <Container className={classes.form}>
      <form onSubmit={handleSubmit}>
        <Center h={300}>
          <Stack>
        <h3 style={{ textAlign: "center" }}>Sınıf Ekle</h3>
            <TextInput
              label="Sınıf Adı"
              placeholder="Sınıf"
              value={form.values.class_name}
              onChange={(event) =>
                form.setFieldValue("class_name", event.currentTarget.value)
              }
              radius="md"
              autoFocus
            />
            <Button type="submit">Ekle</Button>
          </Stack>
        </Center>
      </form>

      <Center>
        <List>
          {class_names.map((e) => (
            <List.Item>{e.class_names}</List.Item>
          ))}
        </List>
      </Center>
      </Container>
    </Paper>

  );
};

export default AdminAddClass;
