import { useForm } from "@mantine/form";
import {
  TextInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Container,
  Stack,
  createStyles,
  rem,
  Select,
  MultiSelect,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../axios/axios";
import GetMyTokenFromCookie from "../axios/getToke";
// import {useNavigate} from "react-router-dom"
// import { GoogleButton, TwitterButton } from '../SocialButtons/SocialButtons';

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: rem(900),
    backgroundSize: "cover",
    // backgroundImage:
    //   "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
  },

  form: {
    border: `${rem(2)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    //   backgroundColor:theme.colors.blue[0],
    backgroundImage: theme.fn.gradient({
      from: theme.colors.blue[1],
      to: theme.colors.gray[0],
      deg: 360,
    }),

    opacity: 0.9,
    minHeight: rem(400),
    maxWidth: rem(450),
    padding: rem(10),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export default function Register(props) {
  const { classes } = useStyles();
  const [error, setError] = useState(false);
  const [student_group, setStudentGroup] = useState([]);
  const [class_names, setClassNames] = useState([]);
  const [roles, setRoles] = useState([]);
  const [terms, setTerms] = useState([]);
  const form = useForm({
    initialValues: {
      email: "",
      username: "",
      name: "",
      last_name: "",
      password: "",
      groupName: "",
      termName: "",
      studentGroup: "",
      className: "",
      first_login:false,
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 3
          ? "Password should include at least 6 characters"
          : null,
    },
  });
  //   const navigate = useNavigate();

  const fetchStudentGroup = async () => {
    return await axiosInstance.get("api/student_group/"
    ).then((response) => {
      return response;
    });
  };
  const fetchStudentClassName = async () => {
    return await axiosInstance.get("api/class_name/"
    ).then((response) => {
      return response;
    });
  };
  const getRoles = async ()=>{
   return await axiosInstance.get("api/roles/")
  }
  const getTerms = async ()=>{
   return await axiosInstance.get("api/term/")
  }
  useEffect(() => {
    fetchStudentGroup().then((resp) => setStudentGroup(resp.data));
    fetchStudentClassName().then((resp) => setClassNames(resp.data));
    getRoles().then((resp) => setRoles(resp.data))
    getTerms().then((resp) => setTerms(resp.data))
  }, []);


  const approveRegister = async (body) => {
    return await axiosInstance.post("api/users/",
      body , {headers: {
        'Authorization': `Token ${GetMyTokenFromCookie()}`
      }}
    ).then((response) => {
      //console.log(response.status);
      if (response.status === 500 || response.status === 400 || response.status === 401 ||response.status === 403  ) {
        setError(true);
        notifications.show({ message: "Hata oluştu ", color: "red" });
      } else {
        notifications.show({
          message: "Başarılı bir şekilde üye olundu ",
          color: "green",
        });
        form.setFieldValue("email", "");
        form.setFieldValue("name", "");
        form.setFieldValue("last_name", "");
        return response;
      }
    });
  };


  const handleRegister = async (e) => {
    e.preventDefault();
    const selectedRole = roles.filter((role) => role.role_name ===form.values.groupName )
    const selectedTerm = terms.filter((term) => term.term_name ===form.values.termName )


    await approveRegister({
      username: form.values.email,
      email: form.values.email,
      password: form.values.last_name.charAt(0).toLowerCase()+ "123456",
      first_name: form.values.name,
      last_name: form.values.last_name,
      group_name: selectedRole.map((role) => role.id)[0],
      term: selectedTerm.map((term) => term.id)[0],
      student_group:form.values.studentGroup.map(e => {return {group_name:e}}) ,
      class_name: form.values.className,
      first_login:false
    })
      //   .then((resp) => console.log(resp))
      .catch((err) => (err));
  };

  return (
    <Paper className={classes.wrapper} radius="md" p="xl" withBorder {...props}>
      <Container className={classes.form}>
        <Text size="lg" weight={500}>
          Metamy Class'a hoş geldiniz,
        </Text>

        {/* <Group grow mb="md" mt="md">
        <p >Google</p>
        <p >Twitter</p>
      </Group> */}

        <Divider
          label="Or continue with email"
          labelPosition="center"
          my="lg"
        />

        <form onSubmit={handleRegister}>
          <Stack>
            <TextInput
              label="Name"
              placeholder="Your name"
              value={form.values.name}
              onChange={(event) =>
                form.setFieldValue("name", event.currentTarget.value)
              }
              radius="md"
              autoFocus
            />

            <TextInput
              label="Lastname"
              placeholder="Soy-isim"
              value={form.values.last_name}
              onChange={(event) => form.setFieldValue('last_name', event.currentTarget.value)}
              radius="md"
              error={error}
            />
          

            <TextInput
              required
              label="Email"
              placeholder="xyz@xyz.com"
              value={form.values.email}
              onChange={(event) =>
                form.setFieldValue("email", event.currentTarget.value)
              }
              error={form.errors.email && "Invalid email"}
              radius="md"
            />
            <Group position="apart" align="center">

            <Select
              mt="md"
              withinPortal
              data={terms.map((term) => term.term_name)}
              placeholder="Dönem Seçiniz"
              label="Dönem Seçiniz"
              onChange={(event) => form.setFieldValue("termName", event)}
            />
            <Button mt="lg">Yeni Ekle</Button>
            </Group>
            <Select
              mt="md"
              withinPortal
              data={roles.map((role) => role.role_name)}
              placeholder="Kayıt Olma Modülünü Seçiniz"
              label="Lütfen kayıt edilen  modülü seçiniz"
              onChange={(event) => form.setFieldValue("groupName", event)}
            />
            {/* <Group position="apart" align="center">
              <Select
                withinPortal
                data={student_group.map((e: any) => e.group_name)}
                placeholder="Kayıt Olunan Dal"
                label="Lütfen kayıt olmak istediğiniz dalı seçiniz"
                onChange={(event: any) =>
                  form.setFieldValue("studentGroup", event)
                }
              />
              <Button mt="lg">Yeni Ekle</Button>
            </Group> */}
            <Group position="apart" align="center">
              <MultiSelect
                withinPortal
                data={student_group.map((e) => {
                  return { value: e.id, label: e.group_name };
                })}
                placeholder="Kayıt Olunan Dal"
                label="Lütfen kayıt olmak istediğiniz dalı seçiniz"
                onChange={(event) =>
                  form.setFieldValue("studentGroup", event)
                }
              />

              <Button mt="lg">Yeni Ekle</Button>
            </Group>
            <Group position="apart" align="center">
              <MultiSelect
                withinPortal
                data={class_names.map((e) => {
                  return { value: e.id, label: e.class_names };
                })}
                placeholder="Sınıf Seçiniz"
                label="Lütfen  sınıf kaydının seçimini yapınız"
                onChange={(event) =>
                  form.setFieldValue("className", event)
                }
              />

              <Button mt="lg">Yeni Ekle</Button>
            </Group>
{/* 
            <Checkbox
              label="I accept terms and conditions"
              checked={form.values.terms}
              onChange={(event) =>
                form.setFieldValue("terms", event.currentTarget.checked)
              }
            /> */}
          </Stack>

          <Group position="apart" mt="xl">
            <Button type="submit" radius="xl">
              Kayıt Et
            </Button>
          </Group>
        </form>
      </Container>
    </Paper>
  );
}
