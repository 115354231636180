import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { IconX, IconCheck } from "@tabler/icons-react";
import {
  PasswordInput,
  Progress,
  Text,
  Popover,
  Box,
  Image,
  Button,
  Center,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import MetamyLogo from "../metamylogosu.png";

import { useMantineTheme } from "@mantine/core";
import { useAppSelector } from "../redux/store";
import axiosInstance from "../axios/axios";
import GetMyTokenFromCookie from "../axios/getToke";
function PasswordRequirement({ meets, label }) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? <IconCheck size="0.9rem" /> : <IconX size="0.9rem" />}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

const requirements = [
  { re: /[0-9]/, label: "Sayı içermelidir." },
  { re: /[a-z]/, label: "Küçük harf içermelidir." },
  { re: /[A-Z]/, label: "Buyük harf içermelidir." },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Özel sembol içermelidir." },
];

function getStrength(password) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export default function PasswordChange() {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [password1, setpassword1] = useState("");
  const [password2, setpassword2] = useState("");

  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const user = useAppSelector((state) => state.user);
  const currentUser = user.data && user.data;
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(password1)}
    />
  ));

  const strength = getStrength(password1);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";
  const [visible, { toggle }] = useDisclosure(false);

  const ChangePasswords = async (body) => {
    return await axiosInstance
      .patch(
        `api/users/${currentUser?.id}/`,

        body,
        {
          headers: {
            Authorization: `Token ${GetMyTokenFromCookie()}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          alert("Şifre değiştirme başarılı");
          window.location.reload();
          // console.log(response)

        } else {
          alert("Hata");
        }
        return response;
      });
  };

  return (
    <div style={{ minHeight: "100vh", background: "#d0ebff", padding: 200 }}>
      <Box maw={500} mx="auto">
        <Center maw={400} h={100} mx="auto">
          <Image width={500} src={MetamyLogo} />
        </Center>
        <Center maw={400} h={100} mx="auto">
          <h2>İlk girişiniz olduğu için lütfen şifrenizi değiştiriniz.</h2>
        </Center>
      </Box>
      <Box maw={350} mx="auto" mt="lg">
        {
          <Text
            style={{
              visibility:
                password1 !== null &&
                password1 !== "" &&
                password2 !== null &&
                password2 !== "" &&
                password1 !== password2
                  ? "visible"
                  : "hidden",
              color: "red",
            }}
          >
            Şifreler uyuşmuyor.
          </Text>
        }

        <Popover
          opened={popoverOpened}
          position="bottom"
          width="target"
          transitionProps={{ transition: "pop" }}
        >
          <Popover.Target>
            <div
              onFocusCapture={() => setPopoverOpened(true)}
              onBlurCapture={() => setPopoverOpened(false)}
            >
              <PasswordInput
                label="Password"
                visible={visible}
                onVisibilityChange={toggle}
                onChange={(event) => setpassword1(event.currentTarget.value)}
              />
            </div>
          </Popover.Target>
          <div>
            <PasswordInput
              label="Confirm password"
              visible={visible}
              onVisibilityChange={toggle}
              onChange={(event) => setpassword2(event.currentTarget.value)}
            />
          </div>
          <Popover.Dropdown>
            <Progress color={color} value={strength} size={5} mb="xs" />
            <PasswordRequirement
              label="En az 6 karakter içermelidir."
              meets={password1.length > 5}
            />
            {checks}
          </Popover.Dropdown>
        </Popover>
        <Button
          display="flex"
          mt={20}
          ml={"auto"}
          variant="gradient"
          gradient={{ from: "#77cfee", to: "#212f4d", deg: 60 }}
          onClick={() =>
            password1 !== null &&
            password1 !== "" &&
            password2 !== null &&
            password2 !== "" &&
            password1 === password2 &&
            ChangePasswords({
              username: currentUser?.username,
              password: password1,
              first_login: true,
              // 
            })
          }
        >
          Şifreyi Güncelle
        </Button>
      </Box>
    </div>
  );
}
