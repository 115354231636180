// VideoList.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  Timeline,
  Text,
  Button,
  ScrollArea,
  Container,
  Select,
  Notification,
  Card,
  Image,
  Group,
  Badge,
  Textarea,
  Anchor,
  Breadcrumbs,
  Tabs,
  Input,
  FileInput,
  Center,
  LoadingOverlay,
  Progress,
  rem,
} from "@mantine/core";
import {
  IconGitBranch,
  IconPhoto,
  IconMessageCircle,
  IconSettings,
  IconTimelineEventText,
  IconTable,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
// import axios from 'axios';
import { IconPlayerPlayFilled } from "@tabler/icons-react";
import RecorVideoImage from "../asserts/DreamShaper_v7_Can_you_design_a_logo_that_says_video_record_Th_2.jpg";
import LessonVideoTemp from "./LessonVideoTemp";
import { getStudentGroup } from "../redux/SideBarSlice";
import { getVideo } from "../redux/VideoSlice";
import axiosInstance from "../axios/axios";
import GetMyTokenFromCookie from "../axios/getToke";
import Records from "../components/LessonVideoPlay";
import AddResources from "./AddResources";
import VideoDragDrop from "./DragDrop";

const VideoList = ({selectedTerm,  selectedGroup}) => {
  // const [videos, setVideos] = useState([]);
  // const [teacherVideos, setTeacherVideo] = useState([]);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [deleteId, setDeleteVideo] = useState("");
  const [toggleFetch, setTogleFetch] = useState(false);

  const user = useAppSelector((state) => state.user);
  const currentUser = user.data && user.data;
  const allVideos = useAppSelector((state) => state.videos);
  const [dataState, setDataState] = useState("");
  const dispatch = useAppDispatch();




  useEffect(() => {
    dispatch(getStudentGroup());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTogleFetch(true);
    setDataState("");
    selectedGroup == undefined && selectedTerm == undefined && alert(`Donem veya Kurs Secilmedi`)
    title == "" && desc == "" && alert(`Baslik veya aciklama girilmedi`)
    try {
      const formData = new FormData();
      formData.append("video_file", file, file.name);
      formData.append("title", title);
      formData.append("desc", desc);
      formData.append("lecturer", currentUser.id);
      formData.append("term", selectedTerm);
      formData.append("group", selectedGroup);
      formData.append("index", allVideos?.data?.length + 1);
      const response = 
      selectedGroup !== undefined && selectedTerm !== undefined && 
      title !== "" && desc !== "" &&
      await axiosInstance.post("api/lesson_video/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${GetMyTokenFromCookie()}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
      });
      setTitle("");
      setDesc("");
      setFile(null);
      setDataState("Eklendi");
      setTogleFetch(false);
      notifications.show({
        message: "Video başarılı birşekilde eklendi",
        color: "green",
      });

      return response.data;
    } catch (error) {
      notifications.show({ message: "Video ekleme başarısız", color: "red" });
      setTitle("");
      setDesc("");
      setFile(null);
      setDataState("hata");
    }
    setTitle("");
    setDesc("");
    setFile(null);
    setTogleFetch(false);
  };


  useEffect(() => {
    // selectedTerm !== undefined &&
      selectedGroup &&
      dispatch(
        getVideo({
          group: selectedGroup,
          term:
            (user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici")
              ? selectedTerm
              : currentUser.term,
        })
      );
  }, [selectedTerm, selectedGroup, dataState]);


  const handleDeleteVideo = async (e) => {
    e.preventDefault();
    setTogleFetch(true);
    setDataState("");
    const [deletedVideo] = allVideos.data.filter((e) => e.id == deleteId);
    const videoFileUrl = deletedVideo?.video_file.split("/media");

    const options = {
      method: "DELETE",
      headers: { AccessKey: process.env.REACT_APP_STORAGE },
    };

    fetch(
      `https://storage.bunnycdn.com/lmsmetamy/media${videoFileUrl[1]}`,
      options
    )
      .then((response) => response.json())
      .catch((err) => console.error(err));

    try {
      deleteId !== "" &&
        (await axiosInstance
          .delete(`api/lesson_video/${deleteId}`, {
            headers: {
              Authorization: `Token ${GetMyTokenFromCookie()}`,
            },
          })
          .then(
            notifications.show({
              message: "Video başarılı birşekilde silindi",
              color: "green",
            })
          ));
      setDataState("Silindi");
    } catch {
      (
        notifications.show({
          message: "Silme Başarısız Hata Oluştu ",
          color: "red",
        })
      );
    }
    setTogleFetch(false);
    setDataState("Hata");
  };



  return (
    <div>
      <LoadingOverlay
        visible={allVideos?.loading}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 10 }}
      />

      {(user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici") && (
        <div>
     
          <Tabs color="dark"  variant="pills" defaultValue="lecture">
            <Tabs.List grow style={{backgroundColor:"#FBCC3B"}}>
              <Tabs.Tab value="lecture" icon={<IconPhoto size="0.8rem" />}>
                Videolar
              </Tabs.Tab>
              <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
                Video Ekle
              </Tabs.Tab>
              <Tabs.Tab value="video-resource" icon={<IconSettings size="0.8rem" />}>
                Video-Kaynak Ekle
              </Tabs.Tab>
              <Tabs.Tab
                value="lecture_edit"
                icon={<IconSettings size="0.8rem" />}
              >
                Video Düzenle
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="lecture" pt="xs">
                <Records />

           
            </Tabs.Panel>
            <Tabs.Panel value="video-resource" pt="xs">
            <Container style={{ width: "500px", backgroundColor:"#FBCC3B", padding:"2rem", marginTop:"3rem", borderRadius:"1rem" }}>
            <h2>Video Kaynak Ekle</h2>
                <AddResources />

            </Container>


           
            </Tabs.Panel>
            <Tabs.Panel value="settings" pt="xs">
            <Container style={{ width: "500px", backgroundColor:"#FBCC3B", padding:"2rem", marginTop:"3rem", borderRadius:"1rem" }}>
              <h2>Video Ekle</h2>
                <form onSubmit={handleSubmit}>
               
                  <Textarea
                    placeholder="Video Başlığı Giriniz"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    withAsterisk
                    label="Video Başlığı"
                    size="md"
                    radius="md"
                    style={{margin:"0.5rem"}}
                    leftSection={<IconTable  />}

                  />
                  <Textarea
                    size="md"
                    radius="md"
                    label="Video Açıklamaları"
                    placeholder="Video Açıklamalarır"
                    onChange={(e) => setDesc(e.target.value)}
                    value={desc}
                    withAsterisk
                    style={{margin:"0.5rem"}}
                  />
                  <FileInput
                    radius="md"
                    size="md"
                    label="Video Yükle"
                    withAsterisk
                    placeholder="Video Seçiniz"
                    value={file}
                    onChange={setFile}
                    style={{margin:"0.5rem"}}
                  />

                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="submit">Videoyu Yükle</Button>

                  </div>
                </form>
                {toggleFetch && <h5 style={{textAlign:"center"}}>{progress.toFixed(2) * 2} %</h5>}
                {toggleFetch &&  <Progress style={{marginTop:"2rem"}} value={progress * 2} /> } 
              </Container>
            </Tabs.Panel>
            <Tabs.Panel value="lecture_edit" pt="xs">
            <Container style={{ width: "500px", backgroundColor:"#FBCC3B", padding:"2rem", marginTop:"3rem", borderRadius:"1rem" }}>
            <h2>Video Sıralama Düzenle- Güncelle</h2>

              <form
                style={{ justifyContent: "center", display: "flex" }}
                onSubmit={(e) => handleDeleteVideo(e)}
              >
                <div style={{ width: "500px" }}>
                   <VideoDragDrop setDataState={setDataState} selectedGroup={selectedGroup} />
                </div>
              </form>

            </Container>

            </Tabs.Panel>
          </Tabs>
          {toggleFetch && (
            <Notification
              loading
              withCloseButton={false}
              color="red"
              title="Lütfen Bekleyiniz"
            >
              İşleminiz tamamlanıyor lütfen bekleyiniz
            </Notification>
          )}
        </div>
      )}
      {user?.role?.role_name === "Öğrenci" && (
        <>
          <LoadingOverlay
            visible={allVideos?.loading}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 10 }}
          />

          {/* <Breadcrumbs style={{ margin: "1rem" }}>{breadcrapItems}</Breadcrumbs> */}
            <Records />
        </>
      )}
    </div>
  );
};

export default VideoList;
