import { Modal } from "@mantine/core";
import React from "react";
// import { Viewer } from '@react-pdf-viewer/renderer';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const LessonCheetTemp = ({ cheet }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div>
    {cheet !== undefined &&
    
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.js">
          <div
            style={{
              height: "90%",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Viewer
              fileUrl={`${cheet?.cheet_file}`}
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        </Worker> }
      </div>
  );
};

export default LessonCheetTemp;
