import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import axiosInstance from "../axios/axios";
import { Button, Progress } from "@mantine/core";
import Zip from "../asserts/zip.png";
import Pdf from "../asserts/pdf.png";
import Doc from "../asserts/doc.png";
import Xls from "../asserts/xls.png";
import Ppt from "../asserts/ppt.png";
import Text from "../asserts/text.png";
import Ipybn from "../asserts/ipynb.png";
import Sql from "../asserts/sql.png";
import { useAppSelector } from "../redux/store";
import { IconRowRemove, IconTrash, IconTrashFilled } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

const FileUpload = ({ selectedVideo }) => {
  const [oldFiles, setOldFiles] = useState();
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [toggleFetch, setTogleFetch] = useState(false);
  const user = useAppSelector((state) => state.user);



  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };
  const getVideoFiles = async () => {
    const response =
      selectedVideo &&
      (await axiosInstance.get(
        `api/lesson-video-file/?video_id=${selectedVideo}`
      ));
    setOldFiles(response.data);
  };
  const deleteVideoFiles = async (id) => {
    setTogleFetch(true)
    const response =
      selectedVideo &&
      (await axiosInstance.delete(
        `api/lesson-video-file/?file_id=${id}`
      ).then(
        notifications.show({
          message: "Video başarılı birşekilde silindi",
          color: "green",
        })))
      setTogleFetch(false)

    // setOldFiles(response.data);
  };
  useEffect(() => {
    getVideoFiles();
  }, [selectedVideo, toggleFetch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onButtonClick = (url) => {
    const type = url.split(".").pop().toLowerCase();

    // using Java Script method to get PDF file
    if (type == `pdf`) {
      fetch(url).then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = url;
          alink.click();
        });
      });
    } else {
      const pdfUrl = url;
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.download = "document.pdf"; // specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const renderFiles = () => {
    return files?.map((file, index) => (
      <li
        key={index}
        style={{fontWeight:600}}
      >
        {file.name}
      </li>
    ));
  };


  function letterShorter(dosyaAdi) {
    const maxUzunluk = 30;
    const uzantiIndex = dosyaAdi.lastIndexOf('.'); // Dosya adındaki son nokta karakterinin indeksini buluyoruz
  
    if (uzantiIndex !== -1) {
      const dosyaAdiKisaltma = dosyaAdi.slice(0, maxUzunluk); // İlk 15 karakteri alıyoruz
      const uzanti = dosyaAdi.slice(uzantiIndex); // Uzantıyı alıyoruz
      const kisaltilmisDosyaAdi = dosyaAdiKisaltma.slice(0, maxUzunluk - uzanti.length); // İlk 15 karakterden uzantıyı çıkarıyoruz
      return kisaltilmisDosyaAdi + "..." + uzanti; // Kısaltılmış adın sonuna ... ve uzantıyı ekleyerek döndürüyoruz
    } else if (dosyaAdi.length > maxUzunluk) {
      return dosyaAdi.slice(0, maxUzunluk) + "..."; // İlk 15 karakteri alıp sonuna ... ekleyerek döndürüyoruz
    } else {
      return dosyaAdi; // Dosya adı 15 karakterden kısa veya eşitse, aynı şekilde döndürüyoruz
    }
  }

  const oldRenderFiles = () => {
    return oldFiles?.map((file, index) => (
      <div style={{position:"relative", marginTop:"1rem"}}>
                {(user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici") && (
        <IconTrash onClick={() => deleteVideoFiles(file.id)} style={{position:"absolute", left:0, margin:"-0.5rem", color:"red", cursor:"pointer"}} />)}
      <li
        style={{
          listStyleType: `none`,
          cursor: `pointer`,
          margin: `1rem`,
          maxWidth: `5rem`,
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          
        }}
        key={index}
        onClick={() => onButtonClick(file.file)}
      >

        <img
          style={{ width: `75px` }}
          src={
            file.file.split(".").pop().toLowerCase() == `zip`
              ? Zip
              : file.file.split(".").pop().toLowerCase() == `rar`
              ? Zip
              : file.file.split(".").pop().toLowerCase() == `pdf`
              ? Pdf
              : file.file.split(".").pop().toLowerCase() == `png`
              ? file.file
              : file.file.split(".").pop().toLowerCase() == `jpg`
              ? file.file
              : file.file.split(".").pop().toLowerCase() == `jpeg`
              ? file.file
              : file.file.split(".").pop().toLowerCase() == `png`
              ? file.file
              : file.file.split(".").pop().toLowerCase() == `xlsx`
              ? Xls
              : file.file.split(".").pop().toLowerCase() == `docx`
              ? Doc
              : file.file.split(".").pop().toLowerCase() == `ppt`
              ? Ppt
              : file.file.split(".").pop().toLowerCase() == `ipynb`
              ? Ipybn
              : file.file.split(".").pop().toLowerCase() == `sql`
              ? Sql
              : Text
          }
          alt={file.file_name}
        />
        <h5 style={{ wordBreak: `break-word`, textAlign: `center` }}>
          {letterShorter(file.file_name)}
        </h5>
        {/* {file.file_name} */}
      </li>
      </div>
    ));
  };

  const handleUpload = async () => {
    const formData = new FormData();
    setTogleFetch(true);
    files.forEach((file, index) => {
      formData.append(`file`, file);
    });
    formData.append("video", selectedVideo);
    const response = await axiosInstance.post(
      "api/lesson-video-file/",
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data; charset=utf-8',
          //   Authorization: `Token ${GetMyTokenFromCookie()}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
      }
    );
    setTogleFetch(false);
    setFiles([]);
  };

  return (
    <>
      <div className="container">
        <ul style={{ display: `flex`, justifyContent: `center`, flexWrap:"wrap"
 }}>
          {oldRenderFiles()}
        </ul>
        {(user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici") && (
          <>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Dosyaları buraya sürükleyin</p>
              ) : (
                <p>Dosyaları yüklemek için buraya sürükleyin veya tıklayın</p>
              )}
            </div>
            <ul>{renderFiles()}</ul>
            <Button style={{ marginTop: "2rem" }} onClick={handleUpload}>
              Yükle
            </Button>
            {toggleFetch && (
              <h5 style={{ textAlign: "center" }}>
                {progress.toFixed(2) * 2} %
              </h5>
            )}
            {toggleFetch && (
              <Progress style={{ marginTop: "2rem" }} value={progress * 2} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FileUpload;
