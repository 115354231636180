import axiosInstance from "../axios/axios";
import GetMyTokenFromCookie from "../axios/getToke";
import { useEffect, useState } from "react";
import "./AdminuserList.css";
import { Button, Select } from "@mantine/core";

export function AdminUserList() {
  const [users, setUsers] = useState();
  const [selectedTerm, setSelectesTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [term, setTerm] = useState([]);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);

  const handleDelete = async (user) =>{
    await axiosInstance
      .delete(`api/users/${user.id}`,  {
        headers: {
          Authorization: `Token ${GetMyTokenFromCookie()}`,
        },
      }).then(
        alert(`Kullanici Silindi`)
      )

  }

  useEffect(() => {
    selectedTerm !== `` && selectedGroup !==``&& selectedRole !== `` && fetchStudentList().then((resp) => setUsers(resp.data));
  }, [selectedRole,selectedGroup,selectedTerm,handleDelete]);
  const fetchStudentList = async () => {
    return await axiosInstance
      .get(`api/users/?student_group=${selectedGroup}&group_name=${selectedRole}&term=${selectedTerm}`, {
        headers: {
          Authorization: `Token ${GetMyTokenFromCookie()}`,
        },
      })
      .then((response) => {
        return response;
      });
  };


  useEffect(() => {
    async function getTermGrup() {
      const response = await axiosInstance.get(`api/term/`);
      const response2 = await axiosInstance.get(`api/student_group/`);
      const response3 = await axiosInstance.get(`api/roles/`);
      // const response = await axiosInstance.get(`api/student-group/`);

      setTerm(response.data);
      setGroups(response2.data);
      setRoles(response3.data);
    }
    getTermGrup();
  }, []);

  const getTermName = (id) =>{
    const [termData] = term.filter(e => e.id == id)
    return termData?.term_name

  }
  const getRoleName = (id) =>{
    const [roleData] = roles.filter(e => e.id == id)
    return roleData?.role_name

  }
  const handleResetPass = async (user) =>{
    const newPass = {password: user.last_name.charAt(0).toLowerCase()+ "123456",first_login:false}
    await axiosInstance
      .patch(`api/users/${user.id}`,newPass,  {
        headers: {
          Authorization: `Token ${GetMyTokenFromCookie()}`,
        },
      }).then(
        alert(`Sifre Sifirlandi`)
      )

  }


  return (
    <>
    <div style={{display:`flex`, justifyContent:`center`}}>
    <Select
                label="Dönem Seçiniz"
                placeholder="Dönem Seç"
                data={term?.map((term) => {
                  return { value: term.id, label: term.term_name };
                })}
                value={selectedTerm}
                onChange={setSelectesTerm}
                style={{
                  borderRadius: "30px",
                  backgroundColor: "#64aec8",
                  margin: "0.3rem",
                  textAlign: `center`,
                }}
              />
 <Select
              label="Kurs Seçiniz"
              placeholder="Kurs Seç"
              data={groups.map((group) => {
                return { value: group.id, label: group.group_name };
              })}
              // defaultValue={"Seçiniz"}
              value={selectedGroup}
              onChange={setSelectedGroup}
              style={{
                borderRadius: "30px",
                backgroundColor: "#64aec8",
                margin: "0.3rem",
                textAlign: `center`,
              }}
            />
 <Select
              label="Rol Seçiniz"
              placeholder="Rol Seç"
              data={roles.map((role) => {
                return { value: role.id, label: role.role_name };
              })}
              // defaultValue={"Seçiniz"}
              value={selectedRole}
              onChange={setSelectedRole}
              style={{
                borderRadius: "30px",
                backgroundColor: "#64aec8",
                margin: "0.3rem",
                textAlign: `center`,
              }}
            />

    </div>
     
      <div className="container">
        <h2>
          Ogrenci Listesi <small>MetaWorld</small>
        </h2>
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-1">Sira</div>
            <div className="col col-2">Isim Soyisim</div>
            <div className="col col-3">E-Posta</div>
            <div className="col col-4">Donem</div>
            <div className="col col-5">Grup</div>
            <div className="col col-6">Rol</div>
          </li>
          {users?.map((user,index) => (
          <li className="table-row">
            <div className="col col-1" data-label="Job Id">
            {index + 1}
            </div>
            <div className="col col-2" data-label="Customer Name">
            {user.first_name + ` ` + user.last_name }
            </div>
            <div className="col col-3" data-label="Customer Name">
            {user.username }
            </div>
            <div className="col col-4" data-label="Amount">
            {getTermName(user.term) }
            </div>
            <div className="col col-5" data-label="Payment Status">
            {user.student_group.map(e => <h4>{e.group_name}</h4> ) }
            </div>
            <div className="col col-6" data-label="Payment Status">
              <h4>{getRoleName(user.group_name)}</h4>
              <Button onClick={() =>handleResetPass(user)}>Sifre Sifirla</Button>
              <Button onClick={() =>handleDelete(user)}>Sil</Button>
            </div>
          </li>
      ))}

          
        </ul>
      </div>

    </>
  );
}
