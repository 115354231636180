import React from 'react';
import './App.css';
import AppRouter from './appRouter/Approuter';

function App() {
  return (
    <AppRouter />
  );
}

export default App;
