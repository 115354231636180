import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useAppSelector } from "../redux/store";
import { IconCircleLetterI } from '@tabler/icons-react';
import { Modal, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Input, TextInput } from '@mantine/core';
import axiosInstance from "../axios/axios";
import GetMyTokenFromCookie from "../axios/getToke";
import { notifications } from "@mantine/notifications";
import { IconEraser } from '@tabler/icons-react';






function VideoDragDrop({setDataState}) {
  const allVideos = useAppSelector((state) => state.videos);

  const [deleteId, setDeleteVideo] = useState("");
  const [toggleFetch, setTogleFetch] = useState(false);


  const [opened, { open, close }] = useDisclosure(false);
  const [
    OpenmodalDelete,
    setOpenmodalDelete,
  ] = useState(false);
  const [videos, setVideos] = useState([]);
  const [desc, setDesc] = useState([]);
  const [title, setTitle] = useState([]);
  const [columns, setColumns] = useState([]);
  const [taskStatus, setTaskStatus] = useState({
    requested: {
      items: allVideos?.data
    },
  });
  useEffect(() => {
    setColumns(taskStatus);
  }, [taskStatus]);
  useEffect(() => {
    setTaskStatus({
      requested: {
        items: allVideos?.data
      },
    });
  }, [allVideos]);
  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    setDataState("")
  
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      const updatedDestItems = destItems.map((item, index) => ({ ...item, index }));
      const updatedSourceItems = sourceItems.map((item, index) => ({ ...item, index }));
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: updatedSourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: updatedDestItems
        }
      });
      const updatedVideo = { index: destination.index };
      axiosInstance.patch(`api/lesson_video/${removed.id}/`, updatedVideo, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Token ${GetMyTokenFromCookie()}`
        },
      })
        .then(response => {
          setDataState("updateds.")
        })
        .catch(error => {
          // window.alert("Something went wrong");
        });
  
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      const updatedItems = copiedItems.map((item, index) => ({ ...item, index }));
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: updatedItems
        }
      });
      // const updatedVideo = { index: destination.index };
      updatedItems.map(item => 
        axiosInstance.patch(`api/lesson_video/${item.id}/`, { index: item.index }, {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Token ${GetMyTokenFromCookie()}`
          },
        })
          .then(response => {
            // window.alert("Video moved successfully");
            setDataState("updated.")
          })
          .catch(error => {
            // window.alert("Something went wrong");
          })
        )
  
    }
  
  };
  


  const handleDeleteVideo = async (e) => {
    e.preventDefault();
    setTogleFetch(true);
    setDataState("");
    const [deletedVideo] = allVideos?.data?.filter((e) => e.id == deleteId);
    const videoFileUrl = deletedVideo?.video_file.split("/media");

    const options = {
      method: "DELETE",
      headers: { AccessKey: process.env.REACT_APP_STORAGE },
    };

    fetch(
      `https://storage.bunnycdn.com/lmsmetamy/media${videoFileUrl[1]}`,
      options
    )
      .then((response) => response.json())
      .catch((err) => console.error(err));

    try {
      deleteId !== "" &&
        (await axiosInstance
          .delete(`api/lesson_video/${deleteId}`, {
            headers: {
              Authorization: `Token ${GetMyTokenFromCookie()}`,
            },
          })
          .then(
            notifications.show({
              message: "Video başarılı birşekilde silindi",
              color: "green",
            })
          ));
      setDataState("Silindi");
    } catch {
      (
        notifications.show({
          message: "Silme Başarısız Hata Oluştu ",
          color: "red",
        })
      );
    }
    setTogleFetch(false);
    setDataState("Hata");
  };
  function handlemodal(item) {
    open();
    setVideos(item);
    setDesc(item.desc);
    setTitle(item.title);
  }
  function handleSubmit() {
    setDataState("")

    const updatedVideo = {
      title: title,
      desc: desc,
      video: videos.video_url,
      lesson: videos.lesson,
      id: videos.id,
    }




    axiosInstance.patch(`api/lesson_video/${videos.id}/`, updatedVideo, {
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Token ${GetMyTokenFromCookie()}`
      },
    });


    close();
    setDataState("Güncellendi.")
  }
  function handleDeletemodal(item) {
    setOpenmodalDelete(true);
    setDeleteVideo(item.id);

  }



  return (
    <div>  

      <div
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
                key={columnId}
              >

                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "lightgrey",
                            padding: 4,

                            width: 250,
                            minHeight: 500
                          }}
                        >
                          {column.items.map((item, index) => {

                            return (
                              <>

                                <Modal opened={opened} onClose={close} title="Authentication">
                                  <h1>{videos.title}</h1>
                                  <TextInput
                                    value={title}
                                    description="Input title"
                                    placeholder={videos.title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  />

                                  <p>{videos.desc}</p>
                                  <TextInput
                                    value={desc}
                                    description="Input description"
                                    placeholder={videos.desc}
                                    onChange={(e) => setDesc(e.target.value)}
                                  />
                                  <div style={{
                                    display: "flex", padding: "15px"
                                    , justifyContent: "space-between"
                                  }}>
                                    <Button
                                      onClick={close}
                                      style={{
                                        background: "red",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={handleSubmit}
                                      style={{
                                        background: "green",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </Modal>


                                <Modal opened={
                                  OpenmodalDelete
                                } onClose={
                                  () => setOpenmodalDelete(false)
                                } title="Delete Confirmation">
                                  <h1>Are you sure you want to delete this video?</h1>
                                  <p>{videos.title}</p>
                                  <div style={{
                                    display: "flex", padding: "15px"
                                    , justifyContent: "space-between"
                                  }}>
                                    <Button
                                      onClick={
                                        () => setOpenmodalDelete(false)
                                      }
                                      style={{
                                        background: "red",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      onClick={handleDeleteVideo}
                                      style={{
                                        background: "green",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </Modal>
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          marginTop: snapshot.isDragging ? "-4.5rem" : "0.5rem",
                                          minHeight: "30px",
                                          zIndex: "999",
                                          backgroundColor: snapshot.isDragging
                                            ? "#64AEC8"
                                            : "#64AEC8",
                                          color: "white",
                                          borderRadius: "10px",
                                          ...provided.draggableProps.style
                                        }}
                                      >
                                        {item.title}
                                        <div style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center"
                                        }}>
                                          <Button
                                            style={{ marginLeft: "10px", background: "#64AEC8", display: "flex", justifyContent: "center", alignItems: "center" }}
                                            onClick={() => handlemodal(item)}
                                          >
                                            <IconCircleLetterI size={25} color="black" />
                                          </Button>

                                          <Button
                                            style={{ marginLeft: "10px", background: "#64AEC8", display: "flex", justifyContent: "center", alignItems: "center" }}
                                            onClick={() => handleDeletemodal(item)}
                                          >
                                            <IconEraser size={25} color="red" />
                                          </Button>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              </>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
}

export default VideoDragDrop;