import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useAppSelector } from "./store";
import axiosInstance from "../axios/axios";
import GetMyTokenFromCookie from "../axios/getToke";

const initialState = {
  data: [],
  loading: false,
  error: "",
};

export const postVideo = createAsyncThunk("postVideo", async (body) => {
  const response = await axiosInstance.post(
    "api/lesson_video/",
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Token ${GetMyTokenFromCookie()}`
      },
    }
  );
  return response.data;
});
export const getVideo = createAsyncThunk("getVideo", async (body) => {
  const response = await  axiosInstance
  .get(
    `api/lesson_video/?group=${body.group}&term=${body.term}`,{headers: {
      'Authorization': `Token ${GetMyTokenFromCookie()}`
    }})
  return response.data;
});

export const VideoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
        builder.addCase(getVideo.pending, (state, action) => {
          state.loading = true;
        });
            try {

        builder.addCase(getVideo.fulfilled, (state, action) => {
          state.data = action.payload;
          state.loading = false;
        });
        
      } catch (error) {
          // state.error = (action.payload);
          // state.loading = false;
        };
        
        

      
    

    

  },
});

export default VideoSlice.reducer;
