import React from "react";
import "./HomeCard.css";
import { useNavigate } from "react-router-dom";
import Records from "../asserts/records.png"
import Documents from "../asserts/documents.png"
import Question from "../asserts/question.png"
import Cheat from "../asserts/cheat.png"
import Cms from "../asserts/cms.png"
const HomeCard = ({ height, count }) => {
  const navigate = useNavigate();
  

  return (
    <div
      style={{ position: "relative", width: "25%", height: `${height}vh` }}
    >
      <div
        style={{
          backgroundColor: `${count % 2 == 0 ? "#64aec8" : "#fbcc3b"}`,
          cursor:"pointer"
        }}
        onClick={() => navigate(count == 0
          ? "/records"
          : count == 1
          ? "/document" 
          : count == 2
          ? "/sheet" 
          : count == 3
          ? "/interview"
          : count == 4
          ? "/cms" :"")}
        className="card transition"
      >
        <h2 className="transition" style={{color:"#004D40"}}>
        {
            count == 0
              ? "RECORDS"
              : count == 1
              ? "DOCUMENTS" 
              : count == 2
              ? "CHEAT SHEET" 
              : count == 3? "QUESTION BANK"
              : count == 4? "CMS" :""

          }
        </h2>
        <p style={{ textAlign: "center" }}></p>

        <div
          style={{
            background: `${
              count == 0
                ? `url(${Records}) no-repeat center center`
                : count == 1
                ?`url(${Documents}) no-repeat center center`
                : count == 2
                ? `url(${Cheat}) no-repeat center center`
                : count == 3
                ? `url(${Question}) no-repeat center center`
                : count == 4
                ? `url(${Cms}) no-repeat center center`
                : ""
            }`,
          }}
          className="card_circle transition"
        />
      </div>
    </div>
  );
};

export default HomeCard;
