import { Modal } from "@mantine/core";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { encrypt } from "../util/cry";

const LessonVideoTemp = ({ video, setIsPlaying, menuState }) => {
  const playerRef = useRef(null);

  const playerConfig = {
    file: {
      attributes: {
        controlsList: "nodownload", // İndirme özelliğini devre dışı bırakır
        onContextMenu: (e) => e.preventDefault(), // Sağ tıklamayı engeller
      },
    },

    attributes: {
      controls: true,
      disablePictureInPicture: true,
      disablecontextmenu: true,
      controlsList: "nodownload",
    },
  };
  return (

    <div>
    <div>
      <ReactPlayer
        style={{
          backgroundColor: "#64aec8",
          borderRadius: "3rem 3rem 0 0",
          padding: "0rem 0rem 0rem 0rem",
          transition: "height 0.9s ease",
        }}
        ref={playerRef}
        url={(video?.video_file)}
        // playing={isPlaying}
        width="100%"
        height= {menuState ? "650px" : "500px"}
        controls
        config={playerConfig}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
    </div>
    </div>
  );
};

export default LessonVideoTemp;
