import {

  Tabs,

} from "@mantine/core";
import {
  IconPhoto,
  IconSettings,
} from "@tabler/icons-react";


import { useAppDispatch, useAppSelector } from "../redux/store";
import { useEffect, useState } from "react";
import LessonIndexEditor from "./LessonIndexEditor";
import LessonNotesEditor from "./LessonNoteEditor";
import { getCourseLesson, getLesson, getLessonIndex } from "../redux/LessonSubSlice";
import Courses from "../pages/Courses";
import LessonIndexMainEditor from "./LessonIndexMainEditor";
export default function LessonIndexTemp({selectedGroup}) {
  const [subjectState, setSubjectState] = useState("");
  const [mainSubjectState, setMainSubjectState] = useState("");
  const [selectedSubject, setSelectedSubject] = useState();


  const user = useAppSelector((state) => state.user);
  const documentsSub = useAppSelector((state) => state.lessonIndex);

  const dispatch = useAppDispatch();
  useEffect(() => {
    selectedSubject !== undefined && dispatch(getLessonIndex(selectedSubject));
    dispatch(getCourseLesson(selectedGroup));
    }
  , [selectedSubject,selectedGroup,subjectState,mainSubjectState]);

 


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <>


      <div style={{ display: "flex", flexDirection: "column" }}>
        {(user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici") && (
          <div>
            <Tabs color="indigo" variant="pills" defaultValue="lecture">
              <Tabs.List grow style={{backgroundColor:"#BBDEFB"}}>
                <Tabs.Tab value="lecture" icon={<IconPhoto size="0.8rem" />}>
                  Ders
                </Tabs.Tab>
                <Tabs.Tab
                  value="ana_konu"
                  icon={<IconSettings size="0.8rem" />}
                >
                  Ana Konu İşlemleri
                </Tabs.Tab>
                <Tabs.Tab
                  value="alt_konu"
                  icon={<IconSettings size="0.8rem" />}
                >
                  Alt Konu İşlemleri
                </Tabs.Tab>
                <Tabs.Tab
                  value="alt_konu_düzenle"
                  icon={<IconSettings size="0.8rem" />}
                >
                  Konu Düzenle
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="lecture" pt="xs">
              <Courses setSelectedSubject={setSelectedSubject}  />
              </Tabs.Panel>
              <Tabs.Panel value="ana_konu" pt="xs">
              <LessonIndexMainEditor 
              selectedGroup={selectedGroup}
              mainSubjectState={mainSubjectState}
              setMainSubjectState={setMainSubjectState}
               />
              </Tabs.Panel>
              <Tabs.Panel
                value="alt_konu"
                pt="xs"
              >
                <LessonIndexEditor
                  subjectState={subjectState}
                  setSubjectState={setSubjectState}
                  lessonData={documentsSub}
                  selectedSubject={selectedSubject}
                  setSelectedSubject={setSelectedSubject}
                />
              </Tabs.Panel>
              <Tabs.Panel value="alt_konu_düzenle" pt="xs">
                <LessonNotesEditor 
                  subjectState={subjectState}
                  setSubjectState={setSubjectState}
                  mainSubjectState={mainSubjectState}
                  setMainSubjectState={setMainSubjectState}
                  setSelectedSubject={setSelectedSubject}


                />
              </Tabs.Panel>

            </Tabs>
          </div>
        )}
        {user?.role?.role_name === "Öğrenci" && (

            <Courses setSelectedSubject={setSelectedSubject} />

        )}
      </div>
    </>
  );
}
