import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor, EditorContent } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Image from "@tiptap/extension-image";

import { IconColorPicker } from "@tabler/icons-react";
import { useCallback, useState } from "react";
import axios from "axios";
import { Button, Center, FileButton, Select } from "@mantine/core";
import { useRichTextEditorContext } from "@mantine/tiptap";
import { IconPhoto } from "@tabler/icons-react";
import { useEffect } from "react";
import { useAppSelector } from "../redux/store";
import { useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import axiosInstance from "../axios/axios";

// interface Props {
//   handleImageUpload: (file: File) => Promise<string>;
// }

export const ImageControl = ({ handleImageUpload }) => {
  const { editor } = useRichTextEditorContext();

  useEffect(() => {
    const handlePaste = (event) => {
      if (!event.clipboardData || !event.clipboardData.items) return;

      const item = Array.from(event.clipboardData.items).find(
        (item) => item.type.indexOf("image") === 0
      );

      if (!item) return;

      const file = item.getAsFile();
      if (file) handleImage(file);
    };

    window.document.addEventListener("paste", handlePaste);

    return () => {
      window.document.removeEventListener("paste", handlePaste);
    };
  }, [editor]);

  const handleImage = async (file) => {
    if (!file) return;
    await handleImageUpload(file).then((url) => {
      editor.chain().focus().setImage({ src: url }).run();
    });
  };

  return (
    <FileButton onChange={handleImage} accept="image/png,image/jpeg">
      {(props) => (
        <RichTextEditor.Control
          {...props}
          aria-label="Insert image"
          title="Insert image"
        >
          <IconPhoto stroke={1.5} size="1rem" />
        </RichTextEditor.Control>
      )}
    </FileButton>
  );
};

export default function LessonNoteEditor({
  setSelectedSubject,
  selectedSubject,
}) {
  const userInfo = useAppSelector((state) => state.user);
  const user = userInfo?.data && userInfo?.data;

  const [subjectDetail, setSubjectDetails] = useState([]);
  const [content, setContent] = useState(<>Konu Seçiniz</>);
  const [selectedSub, setSelectedSub] = useState();

  const [dataState, setDataState] = useState("");
  const documentsSub = useAppSelector((state) => state.lessonIndex);

  // useEffect(() => {
  //   subjectDetail.length == 0 && setContent("");
  // }, [selectedSub]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subjects_data =
          selectedSub !== undefined &&
          (await axiosInstance.get(
            `api/lesson_sub_detail/?lessonsubject=${selectedSub}`
          ));

        setSubjectDetails(subjects_data.data);
        setContent(subjectDetail[0]?.content);
      } catch (error) {
        // alert("Hata oluştu:ds");
      }
    };

    fetchData();
  }, [selectedSub]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle,
      Underline,
      Color,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Image.configure({ inline: true, allowBase64: false }),
      // ImageResize
    ],
    content,
    onUpdate(props) {
      const val = setContent(props.editor.getHTML());
    },
  });
  const handleImageUpload = useCallback(
    (file) =>
      new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("image", file);
        axiosInstance
          .post("api/image/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => resolve(result.data.image))
          .catch(() => reject(new Error("Upload failed")));
      }),
    []
  );

  const sendNote = async (e) => {
    e.preventDefault();
    setDataState("");

    try {
      const sameSub = subjectDetail.filter(
        (sub) => sub.lessonsubject === selectedSub
      )[0];
      if (sameSub) {
        const response = await axiosInstance.put(
          `api/lesson_sub_detail/${sameSub.id}/
        `,
          {
            lecturer: user?.id,
            lesson: selectedSubject,
            lessonsubject: selectedSub,
            content: content,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setDataState("Güncellendi");
        notifications.show({ message: "Güncelleme Başarılı", color: "green" });

        return response.data;
      } else {
        const response = await axiosInstance.post(
          `api/lesson_sub_detail/`,
          {
            lecturer: user?.id,
            lesson: selectedSubject,
            lessonsubject: selectedSub,
            content: content,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setDataState("Yeni Eklendi");
        notifications.show({ message: "İçerik Eklendi", color: "green" });
        return response.data;
      }
    } catch (error) {
      notifications.show({ message: "Hata Oluştu", color: "red" });
      setDataState("Hata");

      return [];
    }
  };
  useEffect(() => {
    if (editor && (selectedSub == undefined || selectedSub == "")) {
      editor.chain().setContent().run();
    } else if (editor && subjectDetail[0]?.content) {
      editor.chain().setContent(subjectDetail[0]?.content).run();
    } else if (editor && subjectDetail.length == 0) {
      editor.chain().setContent().run();
    }
  }, [editor, subjectDetail]);

  return (
    <div>
      <form onSubmit={sendNote}>
        <Select
          label="Konu Seç"
          placeholder="Konu Seç"
          data={documentsSub?.lessons?.map((sub) => {
            return { value: sub.id, label: sub.lesson_name };
          })}
          value={selectedSubject}
          onChange={setSelectedSubject}
          style={{
            borderRadius: "30px",
            backgroundColor: "#64aec8",
            margin: "0.3rem",
            textAlign: `center`,
          }}
        />
        <Select
          label="Konu Seçiniz"
          placeholder="Konu Seç"
          data={documentsSub.data.map((subject) => {
            return { value: subject.id, label: subject.sub_title };
          })}
          value={selectedSub}
          onChange={setSelectedSub}
        />
        <br />

        <RichTextEditor editor={editor}>
          <RichTextEditor.Toolbar sticky stickyOffset={60}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Highlight />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>
            <RichTextEditor.ControlsGroup>
              <ImageControl handleImageUpload={handleImageUpload} />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
              <RichTextEditor.Subscript />
              <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Control interactive={false}>
                <IconColorPicker size="1rem" stroke={1.5} />
              </RichTextEditor.Control>
              <RichTextEditor.Color color="#F03E3E" />
              <RichTextEditor.Color color="#7048E8" />
              <RichTextEditor.Color color="#1098AD" />
              <RichTextEditor.Color color="#37B24D" />
              <RichTextEditor.Color color="#F59F00" />
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>
          {/* <EditorContent editor={editor} /> */}

          {subjectDetail && (
            <RichTextEditor.Content
              content={`${(
                <div
                  dangerouslySetInnerHTML={{
                    __html: subjectDetail[0]?.content,
                  }}
                />
              )}`}
            />
          )}
        </RichTextEditor>
        <br />
        <Center>
          <Button type="submit">Konu İçeriği Kaydet</Button>
        </Center>
      </form>
      {/* <div dangerouslySetInnerHTML={{ __html: subjectDetail[0]?.content }} /> */}

      {/* {`${subjectDetail[0]?.content}`} */}
    </div>
  );
}
