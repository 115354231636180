
import HomeCard from "../components/HomeCard";



export default function Home() {


  const arr = [100, 110, 100, 110];
  return (
    <div
      style={{
        display: "flex",
        padding: "0.5rem",
        marginTop: "-3rem",
        flexWrap: window.screen.width > 600 ? "nowrap" : "wrap",
      }}
    >

      {arr.map((e, key) => (
        <HomeCard height={e} count={key} />
      ))}
    </div>
  );
}
