import React, { useEffect, useState } from "react";
import {
  TextInput,
  Switch,
  Group,
  ActionIcon,
  Box,
  Text,
  Button,
  Select,
} from "@mantine/core";
import {
  IconArrowDown,
  IconArrowUp,
  IconTrash,
} from "@tabler/icons-react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useForm } from "@mantine/form";
import axios from "axios";
import { useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import axiosInstance from "../axios/axios";
import { getLessonIndex } from "../redux/LessonSubSlice";

// interface LessonIndex {
//   id?: string;
//   created?: string;
//   sub_title: string;
//   is_active: boolean;
//   is_completed: boolean;
//   index: number;
//   lecturer: string;
//   lesson: string;
// }

// interface ID {
//   id: { id: string,id2:string };
// }

export default function LessonIndexEditor({  setSubjectState, subjectState,
  setSelectedSubject,
  selectedSubject}) {
  const user = useAppSelector((state) => state.user);
  const currentUser = user.data && user.data;
  const documentsSub = useAppSelector((state) => state.lessonIndex);
  const [data, setData] = useState(documentsSub?.data);

  const form = useForm({
    initialValues: {
      subjects: [],
    },
  });
const dispatch = useAppDispatch();


  const DataRequest = async () => {
    try {
      const response = selectedSubject !== undefined && await axiosInstance.get(`api/lesson_sub/?lesson=${selectedSubject}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(getLessonIndex(selectedSubject));
      setData(response?.data);
      return response.data;


    } catch (error) {
      alert("Hata oluştu:", error);
      return [];
    }
  }

  useEffect(() => {
    async function fetchData() {
      const data = await DataRequest();

      const sortedData = data?.sort((a, b) => a.index - b.index);

      const flatDataArray = sortedData?.map((item) => ({
        id: item.id,
        created: item.created,
        sub_title: item.sub_title,
        is_active: item.is_active,
        is_completed: item.is_completed,
        index: item.index,
        lecturer: item.lecturer,
        lesson: item.lesson,
      }));

      form.setFieldValue("subjects", flatDataArray);
    }

    fetchData();
  }, [subjectState,selectedSubject]);
  useEffect(() => {
     function SubStatu() {
      if(subjectState == "Updated"){
        notifications.show({ message: "Veriler Güncellendi", color: "green" })

      }
      else if(subjectState == "NotUpdated"){
        notifications.show({ message: "Güncelleme başarısız", color: "red" })

      }
      else if(subjectState == "NewAdded"){
        notifications.show({ message: "Yeni Konu Eklendi", color: "green" })

      }
      else if(subjectState == "NotNewAdded"){
        notifications.show({ message: "Konu Eklenirken hata oluştu", color: "red" })

      }
      else if(subjectState == "Deleted"){
        notifications.show({ message: "Başrılı silme", color: "green" })

      }
      else if(subjectState == "NotDeleted"){
        notifications.show({ message: "İşlem başarısız", color: "red" })

      }

    }

    SubStatu();
  }, [subjectState]);
  const handleSaveLessonIndex = async (lessonIndex) => {
        setSubjectState("")

    try {
      const existingRecord = data.find((e) => e.id === lessonIndex.id);
      if (existingRecord) {
        existingRecord.is_active = lessonIndex.is_active;
        existingRecord.is_completed = lessonIndex.is_completed;
        // existingRecord.index = lessonIndex.index;
        existingRecord.lecturer = lessonIndex.lecturer;
        existingRecord.lesson = lessonIndex.lesson;
        existingRecord.sub_title = lessonIndex.sub_title;
        await axiosInstance.put(`api/lesson_sub/${lessonIndex.id}/`, existingRecord, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          if (response.status === 200) {
            setSubjectState("Updated")
          } else {
            setSubjectState("NotUpdated")
          }
        });

      } else {
        const response = await axiosInstance.post("api/lesson_sub/", lessonIndex, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 201) {
          const updatedData = [...data, response.data];
          setData(updatedData);
          setSubjectState("NewAdded")
        } else {
          setSubjectState("NotNewAdded")
        }
      }
    } catch (error) {
      alert("API isteği sırasında bir hata oluştu:", error);
    }
    // setSubjectState("cıkıs")

  };

  const handleDeleteLessonIndex = async (id) => {
    setSubjectState("")

    try {
      const response = await axiosInstance.delete(`api/lesson_sub/${id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 204) {
        const updatedData = data.filter((item) => item.id !== id);
        setData(updatedData);
        setSubjectState("Deleted")
      } else {
        setSubjectState("NotDeleted")

      }

    } catch (error) {
      alert("API isteği sırasında bir hata oluştu:", error);
    }

  };

  const setIndex = async (id, newIndex, direction) => {
    const newData = data.map((e, key) => {
      if (direction === 0) {
        if (key === newIndex) {
          e.index = newIndex - 1;
          if (e.index < 0) {
            e.index = 0;
          }
        }
        if (e.id === id) {
          e.index = newIndex;
        }
      }
      if (direction === 1) {
        if (key === newIndex) {
          e.index = newIndex + 1;
        }
        if (e.id === id) {
          e.index = newIndex;
        }
      }
      return e;
    });
    setData(newData);
  };

  const fields = form?.values?.subjects?.map((item, index) => (
    <Group key={index} mt="xs">
      <TextInput
        placeholder="Konu..."
        withAsterisk
        sx={{ flex: 1 }}
        {...form.getInputProps(`subjects.${index}.sub_title`)}
      />
      <Switch
        label="Active"
        {...form.getInputProps(`subjects.${index}.is_active`, {
          type: "checkbox",
        })}
      />
      <ActionIcon color="red" onClick={() => {
        form.removeListItem("subjects", index);
        handleDeleteLessonIndex(item.id);
      }}>
        <IconTrash size="1rem" />
      </ActionIcon>
      <ActionIcon color="red" onClick={() => {
        const newIndex = index + 1;
        form.reorderListItem("subjects", { from: index, to: newIndex });
        setIndex(item.id, newIndex, 0);
      }}>
        <IconArrowDown size="1rem" />
      </ActionIcon>
      {index !== 0 && (
        <ActionIcon color="red" onClick={() => {
          const newIndex = index - 1;
          form.reorderListItem("subjects", { from: index, to: newIndex });
          // handleDeleteLessonIndex(item.id);
          setIndex(item.id, newIndex, 1);
        }}>
          <IconArrowUp size="1rem" />
        </ActionIcon>
      )}
    </Group>
  ));

  return (
    <Box maw={500} mx="auto">
          <Select
                  label="Konu Seç"
                  placeholder="Konu Seç"
                  data={documentsSub?.lessons?.map((sub) => {
                    return { value: sub.id, label: sub.lesson_name };
                  })}
                  value={selectedSubject}
                  onChange={setSelectedSubject}
                  style={{
                    borderRadius: "30px",
                    backgroundColor: "#64aec8",
                    margin: "0.3rem",
                    textAlign: `center`,
                  }}
                />
      {fields?.length > 0 ? (
        <Group mb="xs">
          <Text weight={500} size="lg" sx={{ flex: 1 }}>
            Konu
          </Text>
          <Text weight={500} size="sm" pr={20}>
            Aktif/Pasif
          </Text>
          <Text weight={500} size="sm" pr={20}>
            Konum
          </Text>
        </Group>
      ) : (
        <Text color="dimmed" align="center">
          Boş...
        </Text>
      )}

      {fields}

      <Group position="center" mt="md">
        <Button
          onClick={() =>
            form.insertListItem("subjects", {
              sub_title: "",
              is_active: true,
              is_completed: false,
              index: form.values.subjects.length,
              lecturer: currentUser?.id,
              lesson: selectedSubject,
            })
          }
        >
          Konu Ekle
        </Button>

        <Button  onClick={() => form.values.subjects.map((e) => handleSaveLessonIndex(e))}>
          Kaydet
        </Button>
      </Group>
{/* 
      <Text size="sm" weight={500} mt="md">
        Form values:
      </Text> */}
      {/* <h5>{form.values.subjects.length}</h5>
      <Code block>{JSON.stringify(form.values, null, 2)}</Code> */}
    </Box>
  );
}


