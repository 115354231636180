// VideoList.jsx

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  Timeline,
  Text,
  Button,
  ScrollArea,
  Tabs,
  FileInput,
  Input,
  Center,
  Container,
  Select,
  Notification,
  Card,
  Image,
  Group,
  Badge,
  Textarea,
  Anchor,
  Breadcrumbs,
  Divider,
  LoadingOverlay,
} from "@mantine/core";
import { IconPhoto, IconSettings } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
// import axios from 'axios';
import { IconBook } from "@tabler/icons-react";
import PdfImage from "../asserts/DreamShaper_v7_pdf_logo_creat_2.jpg";
import LessonCheetTemp from "./LessonCheetTemp";
import axiosInstance from "../axios/axios";
import LessonCheatRead from "./LessonCheetRead";
import { getCheet } from "../redux/CheetSlice";

const LessonCheet = ({selectedGroup}) => {
  const [cheets, setCheets] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [deleteId, setDeleteVideo] = useState("");
  const [toggleFetch, setTogleFetch] = useState(false);
  const [dataState, setDataState] = useState("");

  const user = useAppSelector((state) => state.user);
  const currentUser = user.data && user.data;
  const dispatch = useAppDispatch();
  const allCheets = useAppSelector((state) => state.cheet);



  // const [lesson, setLesson] = useState([]);

  // useEffect(() => {
  //   async function getLesson() {
  //     const response = await axios.get(
  //       `http://134.122.92.0/api/lesson/?id=${params.id5}`
  //     );

  //     setLesson(response.data);
  //   }
  //   getLesson();
  // }, [toggleFetch]);
  useEffect(() => {
    // selectedTerm !== undefined &&
      selectedGroup !== undefined &&
      dispatch(
        getCheet({
          group: selectedGroup,
  
        })
      );
  }, [ selectedGroup, dataState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTogleFetch(true);
    try {
      const formData = new FormData();
      formData.append("cheet_file", file, file.name);
      formData.append("title", title);
      formData.append("desc", desc);
      formData.append("lecturer", currentUser.id);
      formData.append("group", selectedGroup);

      const response = await axiosInstance.post("api/lesson_cheet/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setTogleFetch(false);
      setDataState(`Cheet Eklendi`)
      notifications.show({
        message: "Cheat başarılı birşekilde eklendi",
        color: "green",
      });
      setTitle("");
      setFile(null);
      return response.data;
    } catch (error) {
      notifications.show({ message: "Cheat ekleme başarısız", color: "green" });
      setTitle("");
      setFile(null);
      setDataState("Hata");
      //   console.error("Error uploading video:", error);
      //   throw error;
    }
    setTitle("");
    setFile(null);
  };


  const handleDeleteVideo = async (e) => {
    e.preventDefault();
    setDataState(`Cheet Silindi`)

    try {
      deleteId !== "" &&
        (await axiosInstance.delete(`api/lesson_cheet/${deleteId}`).then(
          notifications.show({
            message: "Cheet başarılı birşekilde silindi",
            color: "green",
          })
        ));
    } catch {
      ((error) => console.error(error))(
        notifications.show({
          message: "Silme Başarısız Hata Oluştu ",
          color: "red",
        })
        );
        setDataState("Hata")
    }
    setTogleFetch(false);
  };





  return (
    <div>

      {(user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici") && (
        <div>


          <Tabs color="indigo" variant="pills" defaultValue="lecture">
            <Tabs.List grow style={{ backgroundColor: "#BBDEFB" }}>
              <Tabs.Tab value="lecture" icon={<IconPhoto size="0.8rem" />}>
                Cheats
              </Tabs.Tab>
              <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
                Cheat Ekle
              </Tabs.Tab>
              <Tabs.Tab
                value="lecture_edit"
                icon={<IconSettings size="0.8rem" />}
              >
                Cheat Sil
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="lecture" pt="xs">
            <LessonCheatRead />
            </Tabs.Panel>
            <Tabs.Panel value="settings" pt="xs">
              <Container style={{ width: "500px" }}>
                <form onSubmit={handleSubmit}>
                  <label>Cheet Başlığı</label>
                  <Input
                    placeholder="Cheet Başlığı Giriniz"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <Textarea
                    size="md"
                    radius="md"
                    label="Cheet Açıklamaları"
                    description="Cheet Açıklamaları"
                    placeholder="Cheet Açıklamalarır"
                    onChange={(e) => setDesc(e.target.value)}
                  />
                  <FileInput
                    radius="md"
                    label="Cheet Yükle"
                    withAsterisk
                    placeholder="Cheet Seçiniz"
                    value={file}
                    onChange={setFile}
                  />

                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="submit">Cheet Yükle</Button>
                  </div>
                </form>
              </Container>
            </Tabs.Panel>
            <Tabs.Panel value="lecture_edit" pt="xs">
              <form onSubmit={(e) => handleDeleteVideo(e)}>
                <Select
                  label="Silmek İstediğiniz Videoyu Seçiniz"
                  placeholder="Cheat Seç"
                  data={allCheets.data?.map((video) => {
                    return { value: video.id, label: video.title };
                  })}
                  value={deleteId}
                  onChange={setDeleteVideo}
                />
                <br />
                <Button type="submit">Cheat Sil</Button>
              </form>
            </Tabs.Panel>
          </Tabs>
          {toggleFetch && (
            <Notification
              loading
              withCloseButton={false}
              color="red"
              title="Lütfen Bekleyiniz"
            >
              İşleminiz tamamlanıyor lütfen bekleyiniz
            </Notification>
          )}
        </div>
      )}
      {user?.role?.role_name === "Öğrenci" && (
        <>
        <LessonCheatRead />

        </>

  
      )}
    </div>
  );
};

export default LessonCheet;
