import { Select } from '@mantine/core';
import React, { useState } from 'react'
import { useAppSelector } from '../redux/store';
import FileUpload from './FileDropZone';

const AddResources = () => {
    const allVideos = useAppSelector((state) => state.videos);
    const [selectedVideo, setSelectedVideo] = useState("");


  return (
    <div>
         <Select
                    label="Eklemek İstediğiniz Videoyu Seçiniz"
                    placeholder="Video Seç"
                    data={allVideos?.data?.map((video) => {
                      return { value: video.id, label: video.title };
                    })}
                    value={selectedVideo}
                    onChange={setSelectedVideo}
                  />
                  <FileUpload selectedVideo={selectedVideo} />

    </div>
  )
}

export default AddResources