import {
  createStyles,
  Header,
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
  Badge,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState } from "react";
import MetamyLogo from "../metamylogosu.png";
import Exit from "../asserts/exit.png";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { getUserData } from "../redux/userSlice";
import HomeTabs from "./HomeTabs";

const useStyles = createStyles((theme) => ({
  box: {
    backgroundColor: theme.colors.white,
    zIndex: 999,
  },
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
      zIndex: 999,
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));



export default function Navbar() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();
  const user = useAppSelector((state) => state.user);
  const [token, setToken, removeToken] = useCookies(["mokM"]);

  const currentUser = user.data && user.data;

  const groups = currentUser?.student_group

  

  const Navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    removeToken("mokM", { path: "/" });
    Navigate("/");
    window.location.reload();
  };
  return (
    <Box pb={0}>
      <Header
        className={classes.box}
        height={70}
        px="md"
        style={{
          // boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.74)",
          // backgroundImage: `url('https://metamy.b-cdn.net/media/images/sidebar.webp')`,
          border: "none",
        }}
      >
        <Group position="apart" sx={{ height: "100%" }}>
          <Image
            style={{ cursor: "pointer" }}
            onClick={() => Navigate("/")}
            width={200}
            src={MetamyLogo}
          />

 

          {currentUser ? (
            <Group className={classes.hiddenMobile}>
              <Badge
                // variant="gradient"
                // gradient={{ from: "teal", to: "blue", deg: 60 }}
                style={{
                  borderRadius: "20px",
                  backgroundColor:  "#fbcc3b" ,
                  color:"black",
 
      
                }}
              >
                {user?.role?.role_name}
              </Badge>
              {groups?.map((e) => (
                <Badge
           style={{
                  borderRadius: "20px",
                  backgroundColor:  "#fbcc3b" ,
                  color:"black",
                  maxWidth: '7rem',
                  height:'2rem',
      
                }}
                >
                  {e?.group_name}
                </Badge>
              ))}
              <Badge
           style={{
                  borderRadius: "20px",
                  backgroundColor:  "#fbcc3b" ,
                  color:"black",
                  padding:"1rem"
      
                }}
                >
                Hoş geldin, {currentUser?.first_name?.toUpperCase()}
              </Badge>

            
              <Image
            style={{ cursor: "pointer" }}
            onClick={(e) => handleLogout(e)}
            width={50}
            src={Exit}
          />
            </Group>
          ) : (
            <Group className={classes.hiddenMobile}>
              <Button onClick={() => Navigate("/login")} variant="default">
                Giriş Yap
              </Button>
              <Button
                sx={{
                  width: "12.5rem",
                  "&:hover": {
                    background: "#2290DC",
                    color: "#ffffff",
                    border: "none",
                  },
                }}
              >
                Üye Ol
              </Button>
            </Group>
          )}

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
        {window.location.pathname !== "/" &&
      window.location.pathname !== "/register" &&
      window.location.pathname !== "/add-class" &&
      window.location.pathname !== "/add-group" &&
      window.location.pathname !== "/add-student" &&
      window.location.pathname !== "/add-term" &&
        <HomeTabs/>
        }

      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <a href="/" className={classes.link}>
            Ana sayfa
          </a>
    

          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <Group position="center" grow pb="xl" px="md">
            {currentUser && (
              <>
                <Button
                  variant="default"
                  sx={{
                    width: "12.5rem",
                    "&:hover": {
                      background: "#2290DC",
                      color: "#ffffff",
                      border: "none",
                    },
                  }}
                >
                  Hoş geldin, {currentUser?.first_name?.toUpperCase()}
                </Button>
                <Button
                  onClick={(e) => handleLogout(e)}
                  variant="default"
                  sx={{
                    width: "12.5rem",
                    "&:hover": {
                      background: "#2290DC",
                      color: "#ffffff",
                      border: "none",
                    },
                  }}
                >
                  Çıkış Yap
                </Button>
              </>
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
