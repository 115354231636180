import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios"
import axiosInstance from "../axios/axios";
import GetUidFromCookie from "../axios/getUid";
import GetMyTokenFromCookie from "../axios/getToke";
import { Navigate } from "react-router-dom";


  const initialState  = {
    data:null,
    loading:false,
    error:"",
    role:null
  }
  


export const getUserData = createAsyncThunk("getUserData", async (userId) => {
  const uid =GetUidFromCookie()
  if(uid == ""){
    document.cookie = "mokM" + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // document.cookie = "uid" + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // Navigate("/")
    window.location.reload()

  }
    const response = await axiosInstance.get(`api/users/${uid}`, {headers: {
      'Authorization': `Token ${GetMyTokenFromCookie()}`
    }})

    return response.data
})
export const getRoles = createAsyncThunk("getRoles", async () => {
    const response = await axiosInstance.get(`api/roles/`)
    return response.data
})



export const PersonSlice = createSlice({
    name: "user",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
      builder.addCase(getUserData.pending, (state, action) => {
        state.loading = true
      });
      try {
        builder.addCase(getUserData.fulfilled, (state, action) => {
          state.data = action.payload
          state.loading = false
        });
        builder.addCase(getRoles.fulfilled, (state, action) => {
          state.role = (action.payload).filter((e) => e.id === state?.data?.group_name)[0]
  
        });
        
      } catch (error) {
        builder.addCase(getUserData.fulfilled, (state, action) => {
          state.loading = false
          state.error = "kULLANICI BİLGİLERİ HATALI"
        });

        
      }


  
      // builder.addCase(savePerson.fulfilled, (state, action) => {
      //   state.persons.push(action.payload);
      // });
    },
  });

  export default PersonSlice.reducer;
//   export const { addPerson } = PersonSlice.actions;
  


// export interface CounterState {
//   data: object;
// }

// const initialState: CounterState = {
//   data: {},
// };

// export const userSlice = createSlice({
//   name: "counter",
//   initialState,
//   reducers: {
    
//     fetchUserData: (state) => {
//     //   const fetchUserData = async () => {

//          fetch(
//           `api/users/${token.mytoken.user_id}`,
//           {
//             method: "GET",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `token ${token.mytoken.token}`,
//             },
//           }
//         ).then(async (response) => {
//           state.data = await response.json();
//         })

//         // state.data=fetchUserData().then(res => res)
//       }
//     },
// },
// );

// Action creators are generated for each case reducer function
// export const { fetchUserData } = userSlice.actions;

// export default userSlice.reducer;
