// VideoList.jsx

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../redux/store";
import {
  Timeline,
  Button,

  ScrollArea,
  Tabs,

  Center,
  Container,
  Select,
  Notification,

  Textarea,
  Anchor,
  Breadcrumbs,
  Accordion,
} from "@mantine/core";
import {
  IconPhoto,
  IconHelpHexagon,
  IconSettings,
} from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import {  notifications } from "@mantine/notifications";
// import axios from 'axios';

import { getStudentGroup } from "../redux/SideBarSlice";
import axiosInstance from "../axios/axios";

const InterviewQue = ( {selectedGroup} ) => {
  const [questionsData, setQuestions] = useState([]);
  const [notState, setNotState] = useState("");

  const [question, setQue] = useState("");
  const [answer, setAnsw] = useState("");
  const [deleteId, setDeleteVideo] = useState("");
  const [toggleFetch, setTogleFetch] = useState(false);

  const user = useAppSelector((state) => state.user);
  const currentUser = user.data && user.data;
  const student_group = useAppSelector((state) => state.student_group);

  const student_group_list = student_group.data && student_group.data;
  const data = student_group_list?.map(
    (item) =>
      currentUser?.student_group?.includes(item.id) && {
        group_name: item?.group_name,
        id: item.id,
      }
  );
  const groups = currentUser?.student_group

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getStudentGroup());
  }, []);

  const Navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault()
    setTogleFetch(true);
    setNotState("")

    try {
      const response = await axiosInstance.post(
        `api/interview_que/`,
        {
          lecturer: user?.id,
          question: question,
          answer: answer,
          group: selectedGroup,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setTogleFetch(false);
      setNotState("added")
      setAnsw("")
      setQue("")

    } catch (error) {
      // alert("API isteği sırasında bir hata oluştu:");
    }
  };

  useEffect(() => {
    selectedGroup !== null &&
    axiosInstance
      .get(
        `api/interview_que/?group=${selectedGroup}`
      )
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((error) => console.log("Hata Oluştu",error));
  }, [selectedGroup,notState]);

  const handleDeleteVideo = async (e) => {
    e.preventDefault();
    setTogleFetch(true);
    setNotState("")

    try {
      deleteId !== "" &&
        (await axiosInstance
          .delete(`api/interview_que/${deleteId}`)
          .then(
            notifications.show({
              message: "Soru başarılı birşekilde silindi",
              color: "green",
            })
          ));
    } catch {
        notifications.show({
          message: "Silme Başarısız Hata Oluştu ",
          color: "red",
        })
      
    }
    setTogleFetch(false);
    // setSelectedGroup("")
    setNotState("deleted")
  };

  const breadcrapItems = [
    { title: "Ana Sayfa", href: "/" },
    { title: "Question Bank", href: "#" },
  ].map((item, index) => (
    <Anchor onClick={() => Navigate(item.href)} key={index}>
      {item.title}
    </Anchor>
  ));
  return (
    <div>
      {(user?.role?.role_name === "Öğretmen" || user?.role?.role_name === "Yönetici") && (
        <div>
          {/* <Breadcrumbs style={{ margin: "1rem" }}>{breadcrapItems}</Breadcrumbs> */}

          <Tabs  color="indigo" variant="pills"  defaultValue={"lecture"}>
            <Tabs.List grow style={{backgroundColor:"#BBDEFB"}} >
              <Tabs.Tab value="lecture" icon={<IconPhoto size="0.8rem" />}>
                Sorular
              </Tabs.Tab>
              <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
                Soru Ekle
              </Tabs.Tab>
              <Tabs.Tab
                value="lecture_edit"
                icon={<IconSettings size="0.8rem" />}
              >
                Soru Sil
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="lecture" pt="xs">

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* <div
                  style={{
                    width: "500px",
                  }}
                >
                  <Select
                    label="Alan Seçiniz"
                    placeholder="Alan Seç"
                    data={[{ value: "Seçiniz", group_name: "Seçiniz" }].concat(
                      groups.map((group) => {
                        return { value: group.id, label: group.group_name };
                      })
                    )}
                    defaultValue={"Seçiniz"}
                    value={selectedGroup}
                    onChange={setSelectedGroup}
                  />
                </div> */}
                <div >
                <div
                  style={{
                    width: "980px",
                  }}
                >
                  {/* <ScrollArea h={500} scrollbarSize={8}> */}
                    <div
        
                    >
                      {" "}
                      <Accordion >
                        {questionsData?.map((question, index) => (
                            <Accordion.Item
                              key={question?.question || "Boş"}
                              value={question?.answer || "Boş"}
                            >
                              <Accordion.Control icon={<IconHelpHexagon />} >
                                {question?.question || "Boş"}
                              </Accordion.Control>
                              <Accordion.Panel>
                                {question?.answer || "Boş"}
                              </Accordion.Panel>
                            </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  {/* </ScrollArea> */}

                </div>
                </div>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="settings" pt="xs">
              <Container style={{ width: "500px" }}>
                <form onSubmit={handleSubmit}>
                  {/* <Select
                    label="Alan Seçiniz"
                    placeholder="Alan Seç"
                    data={groups.map((group) => {
                      return { value: group.id, label: group.group_name };
                    })}
                    value={selectedGroup}
                    onChange={setSelectedGroup}
                  /> */}

                  <Textarea
                    size="md"
                    radius="md"
                    label="Soru Metni"
                    // description="Soru Metni"
                    placeholder="Soru Metni"
                    onChange={(e) => setQue(e.target.value)}
                  />
                  <Textarea
                    size="md"
                    radius="md"
                    label="Cevap Metni"
                    // description="Cevap Metni"
                    placeholder="Cevap Metni"
                    onChange={(e) => setAnsw(e.target.value)}
                  />

                  <br />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="submit">Question Ekle</Button>
                  </div>
                </form>
              </Container>
            </Tabs.Panel>
            <Tabs.Panel value="lecture_edit" pt="xs">
              <form
                style={{ justifyContent: "center", display: "flex" }}
                onSubmit={(e) => handleDeleteVideo(e)}
              >
                <div style={{ width: "500px" }}>
                {/* <Select
                    label="Alan Seçiniz"
                    placeholder="Alan Seç"
                    data={groups.map((group) => {
                      return { value: group.id, label: group.group_name };
                    })}
                    value={selectedGroup}
                    onChange={setSelectedGroup}
                  /> */}
                  <Select
                    label="Silmek İstediğiniz Soruyu Seçiniz"
                    placeholder="Soruyu Seç"
                    data={questionsData.map((question) => {
                      return { value: question.id, label: question.question };
                    })}
                    value={deleteId}
                    onChange={setDeleteVideo}
                  />
                  <br />
                  <Button type="submit">Soruyu Sil</Button>
                </div>
              </form>
            </Tabs.Panel>
          </Tabs>
          {toggleFetch && (
            <Notification
              loading
              withCloseButton={false}
              color="red"
              title="Lütfen Bekleyiniz"
            >
              İşleminiz tamamlanıyor lütfen bekleyiniz
            </Notification>
          )}
        </div>
      )}
      {user?.role?.role_name === "Öğrenci" && (
        <>
          {/* <Breadcrumbs style={{ margin: "1rem" }}>{breadcrapItems}</Breadcrumbs> */}
          <Center style={{ display: "flex", flexDirection: "column" }}>
            <h2>Question Bank</h2>
            {/* <Select
              label="Alan Seçiniz"
              placeholder="Alan Seç"
              data={groups.map((group) => {
                return { value: group.id, label: group.group_name };
              })}
              value={selectedGroup}
              onChange={setSelectedGroup}
            /> */}
          </Center>
          <div
            style={{
              // width: "80%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <ScrollArea scrollbarSize={8}>
              <Timeline active={4} bulletSize={30} lineWidth={2}>
                {/* <h1>{lesson[0]?.lesson_name}</h1> */}
                <ScrollArea h={500} scrollbarSize={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                   <div
                  style={{
                    width: "980px",
                  }}
                >
                  {/* <ScrollArea h={500} scrollbarSize={8}> */}
                    <div
        
                    >
                      {" "}
                      <Accordion defaultValue={questionsData[0]?.id} >
                        {questionsData?.map((question) => (
                            <Accordion.Item
                              key={question?.id}
                              value={question?.question}
                              style={{backgroundColor:"#B3E5FC", margin:"0.5rem"}}
                            >
                              <Accordion.Control icon={<IconHelpHexagon />} >
                                {question?.question}
                              </Accordion.Control>
                              <Accordion.Panel>
                                {question?.answer}
                              </Accordion.Panel>
                            </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                    </div>
                  </div>
                </ScrollArea>
                {/* {lessonItems} */}
              </Timeline>
            </ScrollArea>
          </div>
        </>
      )}
    </div>
  );
};

export default InterviewQue;
