import React, { useEffect, useState } from "react";
import {
  TextInput,
  Switch,
  Group,
  ActionIcon,
  Box,
  Text,
  Button,
  Select,
} from "@mantine/core";
import {
  IconArrowDown,
  IconArrowUp,
  IconTrash,
} from "@tabler/icons-react";
import { useAppSelector } from "../redux/store";
import { useForm } from "@mantine/form";
import axios from "axios";
import { useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import axiosInstance from "../axios/axios";



export default function LessonIndexMainEditor({  setMainSubjectState, mainSubjectState,selectedGroup}) {
  const user = useAppSelector((state) => state.user);
  const [data, setData] = useState([]);
  const form = useForm({
    initialValues: {
      subjects: [],
    },
  });

  const DataRequest = async () => {
    try {
      const response = selectedGroup !== null && await axiosInstance.get(`api/lesson/?student_group=${selectedGroup}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      return response.data;
    } catch (error) {
      console.log("Hata oluştudd:", error);
      return [];
    }
  }

  useEffect(() => {
    async function fetchData() {
      const data =  await DataRequest();
      const sortedData = data.sort((a, b) => a.index - b.index);

      const flatDataArray = sortedData.map((item) => ({
        id: item.id,
        created: item.created,
        lesson_name: item.lesson_name,
        is_active: item.is_active,
        is_completed: item.is_completed,
        index: item.index,
        student_group: item.student_group,
      }));

      form.setFieldValue("subjects", flatDataArray);
    }

    selectedGroup !== undefined && fetchData();
  }, [mainSubjectState,selectedGroup]);
  useEffect(() => {
     function SubStatu() {
      if(mainSubjectState == "Updated"){
        notifications.show({ message: "Veriler Güncellendi", color: "green" })

      }
      else if(mainSubjectState == "NotUpdated"){
        notifications.show({ message: "Güncelleme başarısız", color: "red" })

      }
      else if(mainSubjectState == "NewAdded"){
        notifications.show({ message: "Yeni Konu Eklendi", color: "green" })

      }
      else if(mainSubjectState == "NotNewAdded"){
        notifications.show({ message: "Konu Eklenirken hata oluştu", color: "red" })

      }
      else if(mainSubjectState == "Deleted"){
        notifications.show({ message: "Başrılı silme", color: "green" })

      }
      else if(mainSubjectState == "NotDeleted"){
        notifications.show({ message: "İşlem başarısız", color: "red" })

      }

    }

    SubStatu();
  }, [mainSubjectState]);
  const handleSaveLessonIndex = async (lessonIndex) => {
    setMainSubjectState("")

    try {
      const existingRecord = data.find((e) => e.id === lessonIndex.id);
      if (existingRecord) {
        existingRecord.is_active = lessonIndex.is_active;
        existingRecord.is_completed = lessonIndex.is_completed;
        // existingRecord.index = lessonIndex.index;
        existingRecord.student_group = lessonIndex.student_group;
        existingRecord.lesson_name = lessonIndex.lesson_name;
        await axiosInstance.put(`api/lesson/${lessonIndex.id}/`, existingRecord, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          if (response.status === 200) {
            setMainSubjectState("Updated")
          } else {
            setMainSubjectState("NotUpdated")
          }
        });

      } else {
        const response = await axiosInstance.post("api/lesson/", lessonIndex, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 201) {
          const updatedData = [...data, response.data];
          setData(updatedData);
          setMainSubjectState("NewAdded")
        } else {
          setMainSubjectState("NotNewAdded")
        }
      }
    } catch (error) {
      alert("API isteği sırasında bir hata oluştu:");
    }
    // setMainSubjectState("cıkıs")

  };

  const handleDeleteLessonIndex = async (id) => {
    setMainSubjectState("")

    try {
      const response = await axiosInstance.delete(`api/lesson/${id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 204) {
        const updatedData = data.filter((item) => item.id !== id);
        setData(updatedData);
        setMainSubjectState("Deleted")
      } else {
        setMainSubjectState("NotDeleted")

      }

    } catch (error) {
      alert("API isteği sırasında bir hata oluştu:");
    }

  };

  const setIndex = async (id, newIndex, direction) => {
    const newData = data.map((e, key) => {
      if (direction === 0) {
        if (key === newIndex) {
          e.index = newIndex - 1;
          if (e.index < 0) {
            e.index = 0;
          }
        }
        if (e.id === id) {
          e.index = newIndex;
        }
      }
      if (direction === 1) {
        if (key === newIndex) {
          e.index = newIndex + 1;
        }
        if (e.id === id) {
          e.index = newIndex;
        }
      }
      return e;
    });
    setData(newData);
  };

  const fields = form?.values?.subjects?.map((item, index) => (
    <Group key={index} mt="xs">
      <TextInput
        placeholder="Konu..."
        withAsterisk
        sx={{ flex: 1 }}
        {...form.getInputProps(`subjects.${index}.lesson_name`)}
      />
      <Switch
        label="Active"
        {...form.getInputProps(`subjects.${index}.is_active`, {
          type: "checkbox",
        })}
      />
      <ActionIcon color="red" onClick={() => {
        form.removeListItem("subjects", index);
        handleDeleteLessonIndex(item.id);
      }}>
        <IconTrash size="1rem" />
      </ActionIcon>
      <ActionIcon color="red" onClick={() => {
        const newIndex = index + 1;
        form.reorderListItem("subjects", { from: index, to: newIndex });
        setIndex(item.id, newIndex, 0);
      }}>
        <IconArrowDown size="1rem" />
      </ActionIcon>
      {index !== 0 && (
        <ActionIcon color="red" onClick={() => {
          const newIndex = index - 1;
          form.reorderListItem("subjects", { from: index, to: newIndex });
          // handleDeleteLessonIndex(item.id);
          setIndex(item.id, newIndex, 1);
        }}>
          <IconArrowUp size="1rem" />
        </ActionIcon>
      )}
    </Group>
  ));

  return (
    <Box maw={500} mx="auto">
        
      {fields.length > 0 ? (
        <Group mb="xs">
          <Text weight={500} size="lg" sx={{ flex: 1 }}>
            Konu
          </Text>
          <Text weight={500} size="sm" pr={20}>
            Aktif/Pasif
          </Text>
          <Text weight={500} size="sm" pr={20}>
            Konum
          </Text>
        </Group>
      ) : (
        <Text color="dimmed" align="center">
          Boş...
        </Text>
      )}

      {fields}

      <Group position="center" mt="md">
        <Button
          onClick={() =>
            form.insertListItem("subjects", {
              lesson_name: "",
              is_active: true,
              is_completed: false,
              index: form.values.subjects.length,
              student_group: selectedGroup,
            })
          }
        >
          Konu Ekle
        </Button>

        <Button  onClick={() => form.values.subjects.map((e) => handleSaveLessonIndex(e))}>
          Kaydet
        </Button>
      </Group>
{/* 
      <Text size="sm" weight={500} mt="md">
        Form values:
      </Text> */}
      {/* <h5>{form.values.subjects.length}</h5>
      <Code block>{JSON.stringify(form.values, null, 2)}</Code> */}
    </Box>
  );
}


