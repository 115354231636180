import { Button, Center, Container, List, Paper, Stack, Table, TextInput, createStyles, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";

const useStyles = createStyles((theme) => ({
    wrapper: {
      height: rem(900),
      backgroundSize: "cover",
      // backgroundImage:
      //   "url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
    },
  
    form: {
      border: `${rem(2)} solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
      //   backgroundColor:theme.colors.blue[0],
      backgroundImage: theme.fn.gradient({
        from: theme.colors.blue[1],
        to: theme.colors.gray[0],
        deg: 360,
      }),
  
      opacity: 0.9,
      minHeight: rem(400),
      maxWidth: rem(450),
      padding: rem(10),
  
      [theme.fn.smallerThan("sm")]: {
        maxWidth: "100%",
      },
    },
}))  

const AdminAddTerm = (props) => {
  const [postData, setPostData] = useState("");
  const [term_name, setTermName] = useState([]);
  const { classes } = useStyles();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setPostData("");
    try {
      await axiosInstance.post("api/term/", {
        term_name: form.values.term_name,
      });
      setPostData("işlem Başarılı");
    } catch (error) {
      setPostData("Hata");
      return error;
    }
  };

  const form = useForm({
    initialValues: {
      term_name: "",
    },
  });


  const fetchStudentClassName = async () => {
    return await axiosInstance.get("api/term/").then((response) => {
      return response;
    });
  };
  useEffect(() => {
    fetchStudentClassName().then((resp) => setTermName(resp.data));
  }, [postData]);

  return (
    <Paper className={classes.wrapper} radius="md" p="xl" withBorder {...props}>
    <Container className={classes.form}>
    <div>
      <form onSubmit={handleSubmit}>
        <Center h={300}>
          <Stack>
        <h3 style={{ textAlign: "center" }}>Donem Ekle</h3>
            <TextInput
              label="Donem Adı"
              placeholder="Donem"
              value={form.values.class_name}
              onChange={(event) =>
                form.setFieldValue("term_name", event.currentTarget.value)
              }
              radius="md"
              autoFocus
            />
            <Button type="submit">Ekle</Button>
          </Stack>
        </Center>
      </form>
      <Center>
        <List>
          {term_name.map((e) => (
            <List.Item>{e.term_name}</List.Item>
          ))}
        </List>
      </Center>
    </div>
    </Container>
    </Paper>
  );
};

export default AdminAddTerm;
